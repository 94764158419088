/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
/* General css */
.TrustBanner {
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
  background-color: #00005e;
  min-height: 50px;
  padding: 6px 0;
  color: #fff;
  position: relative;
}
.TrustBanner__content {
  max-width: 990px;
  margin: 20px auto 0;
}
.TrustBanner--hotels {
  padding: 30px 0;
  background-color: #fdbb00;
}
.TrustBanner--hotels .TrustBanner__content {
  margin: 0 auto;
}
.TrustBanner--cars {
  background-color: #3b3b3b;
  color: #fff;
}
.TrustBanner__content--title {
  text-align: center;
  font-size: 26px;
  padding: 0 15px;
  margin-bottom: 20px;
  margin-top: 0;
}
.TrustBanner__content--txt {
  font-size: 17px;
  padding: 0 15px;
  text-align: center;
  line-height: 1.2;
}
.TrustBanner__logos {
  text-align: center;
  margin: 30px auto 0;
  max-width: 85%;
}
.TrustBanner__container {
  width: 50%;
  display: inline-block;
  margin-bottom: 20px;
  vertical-align: middle;
}
.TrustBanner__logo {
  height: 36px;
  width: 75%;
}
.TrustBanner__subtitle {
  font-family: SpartanMB, 'Arial', 'sans-serif';
  font-size: 14px;
  text-align: center;
  padding: 0 15px;
}
/* FLIGHTS CSS */
.TrustBanner__container--sc_ryanair,
.TrustBanner__container--sc_bravofly,
.TrustBanner__container--sc_kilroy,
.TrustBanner__container--sc_klm,
.TrustBanner__container--sc_sas,
.TrustBanner__container--sc_travellink {
  width: 33%;
}
.TrustBanner__container--us_flightnetwork,
.TrustBanner__container--us_kiwicom,
.TrustBanner__container--us_westjet {
  width: 33%;
}
.TrustBanner__container--ae_Emirates,
.TrustBanner__container--ae_Etihad_Airways,
.TrustBanner__container--ae_Qatar_Airways {
  width: 33%;
}
.TrustBanner__container--world_Emirates,
.TrustBanner__container--world_lufthansa,
.TrustBanner__container--world_expedia {
  width: 33%;
}
.TrustBanner__logo--gb_thomascook {
  width: 90%;
}
.TrustBanner__logo--gb_easyJet {
  width: 50%;
}
.TrustBanner__logo--gb_flybe {
  width: 40%;
}
.TrustBanner__logo--sc_sas {
  width: 50%;
}
.TrustBanner__container--sc_kilroy {
  width: 25%;
}
.TrustBanner__container--sc_ryanair {
  width: 42%;
}
.TrustBanner__logo--us_flightnetwork,
.TrustBanner__logo--us_westjet,
.TrustBanner__logo--us_aircanada {
  width: 100%;
}
.TrustBanner__logos--ae {
  max-width: 100%;
}
.TrustBanner__logo--ae_Emirates {
  width: 100%;
}
.TrustBanner__logos--world {
  max-width: 95%;
}
/* CARS CSS */
.TrustBanner__container--cars_Europcar,
.TrustBanner__container--cars_avis,
.TrustBanner__container--cars_Hertz,
.TrustBanner__container--cars_Sixt {
  width: 100px;
  max-width: 25%;
  height: 36px;
  display: inline-block;
}
.TrustBanner__logo--cars_Europcar {
  width: 100%;
}
@media only screen and (max-width: 480px) {
  .TrustBanner__logo--world_lufthansa {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .TrustBanner__content--txt {
    padding: 0 50px;
  }
  .TrustBanner__title {
    font-size: 28px;
    margin: 0 auto 20px;
    max-width: 520px;
  }
  .TrustBanner__subtitle {
    font-size: 18px;
  }
  .TrustBanner__container {
    width: 25%;
  }
  .TrustBanner__logos--sc {
    max-width: 90%;
  }
  .TrustBanner__logos--world > .TrustBanner__container {
    width: 130px;
  }
  .TrustBanner__logos--ae > .TrustBanner__container {
    width: 130px;
  }
  .TrustBanner__logos--sc > .TrustBanner__container {
    width: 130px;
  }
  .TrustBanner__logos--de > .TrustBanner__container {
    width: 33%;
  }
}
@media only screen and (min-width: 960px) {
  .TrustBanner__title {
    max-width: 100%;
  }
  .TrustBanner__logos {
    max-width: 100%;
  }
  .TrustBanner__container {
    width: 100%/7;
  }
  .TrustBanner__logos--de > .TrustBanner__container {
    width: 100/7%;
  }
  .TrustBanner__logos--sc > .TrustBanner__container {
    width: 100px;
  }
}
