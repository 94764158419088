/*
** Mixins
*/
/* Input styling */
/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.MobileLoading {
  display: flex;
  flex-flow: column;
  background: #fff;
  border-radius: 3px;
  margin: 0 10px 15px 10px;
}
.MobileLoading__body {
  position: relative;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
  padding: 10px 0 10px 15px;
  height: 200px;
}
.MobileLoading__body__tile--airlines {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  display: block;
  border-radius: 2px;
  height: 12px;
  width: 84px;
  margin-top: 12px;
  margin-bottom: 18px;
}
.MobileLoading__body__tile--itinerary {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  display: block;
  border-radius: 2px;
  height: 22px;
  width: 80%;
  max-width: 330px;
  margin: 30px auto;
}
.MobileLoading__line {
  height: 1px;
  width: calc(100% - 30px);
  margin-left: 15px;
  border-top: 1px solid #dcdcdc;
}
.MobileLoading__footer {
  display: flex;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
  padding: 15px;
  align-items: top;
}
.MobileLoading__footer__tiles {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  margin-left: auto;
}
.MobileLoading__footer__tiles--vendor {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  display: block;
  border-radius: 2px;
  height: 12px;
  width: 60px;
  margin-bottom: 10px;
}
.MobileLoading__footer__tiles--price {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  display: block;
  border-radius: 2px;
  height: 14px;
  margin-bottom: 15px;
  width: 90px;
}
.MobileLoading__footer--btn {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #ffe1d9;
  background: linear-gradient(to right, #ffe1d9 8%, #f3cabf 18%, #ffe1d9 33%);
  background-size: 800px 104px;
  display: block;
  border-radius: 2px;
  height: 40px;
  width: 104px;
  background-color: #ffe1d9;
  border-radius: 3px;
}
