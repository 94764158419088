/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.Tooltip {
  position: absolute;
  z-index: 40;
  margin-top: -10px;
  background: #00005e;
  color: white;
  border-radius: 2px;
  padding: 8px;
  font-size: 12px;
  line-height: 18px;
  border: 0 solid white;
  text-shadow: none !important;
  box-shadow: 0 0 0 rgba(20, 20, 20, 0.25);
  max-width: 280px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
}
.Tooltip::first-letter {
  text-transform: capitalize;
}
.Tooltip__arrow {
  position: absolute;
  z-index: 41;
  margin-top: -10px;
  margin-left: -6px;
  border: 6px solid transparent;
  border-top: 6px solid #00005e;
  border-bottom: 0;
}
.Tooltip,
.Tooltip__arrow {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.Tooltip--loaded,
.Tooltip__arrow--loaded {
  opacity: 1;
}
