/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.GoShareDestination__container {
  display: inline-block;
}
.GoShareDestination__icon {
  width: 40px;
  height: 30px;
  display: inline-block;
  cursor: pointer;
  background: url('/content/icons/facebook_white.svg') no-repeat center;
}
.GoShareDestination__icon--messenger {
  background-image: url('/content/icons/messenger_white.svg');
}
.GoShareDestination__icon--twitter {
  background-image: url('/content/icons/twitter_white.svg');
}
@media only screen and (min-width: 480px) {
  .GoShareDestination__icon {
    width: 45px;
  }
}
