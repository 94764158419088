/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
@keyframes spin-clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin-counter-clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeDown {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
@keyframes fadeUp {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}
@keyframes fadeInHalf {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}
@keyframes scaleIn {
  from {
    transform: scale(0.7);
  }
  to {
    transform: scale(1);
  }
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
@keyframes forwardBackInfinite {
  0% {
    transform: translate3d(0, 0, 0);
  }
  60% {
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}
@keyframes shadowPulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(253, 187, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(253, 187, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(253, 187, 0, 0);
  }
}
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
@keyframes treeBounce {
  0% {
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4% {
    transform: matrix3d(0.432, 0, 0, 0, 0, 0.432, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  7.91% {
    transform: matrix3d(0.889, 0, 0, 0, 0, 0.889, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  11.91% {
    transform: matrix3d(1.189, 0, 0, 0, 0, 1.189, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  15.82% {
    transform: matrix3d(1.276, 0, 0, 0, 0, 1.276, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  20.42% {
    transform: matrix3d(1.196, 0, 0, 0, 0, 1.196, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.92% {
    transform: matrix3d(1.059, 0, 0, 0, 0, 1.059, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.53% {
    transform: matrix3d(0.96, 0, 0, 0, 0, 0.96, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  34.03% {
    transform: matrix3d(0.931, 0, 0, 0, 0, 0.931, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  43.14% {
    transform: matrix3d(0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    transform: matrix3d(1.017, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  70.37% {
    transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  88.59% {
    transform: matrix3d(1.001, 0, 0, 0, 0, 1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
.SingleLineForm__firstInput {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.SingleLineForm__lastInput {
  border-right: none;
}
.SingleLineForm__button {
  position: relative;
  z-index: 40;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.SearchForm {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
.SearchForm #flightform {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.SearchForm__subForm {
  position: relative;
  margin: 6px 0;
}
.SearchForm__origin,
.SearchForm__destination {
  width: 100%;
}
.SearchForm__inputIcon--sunburn svg *,
.SearchForm__travellersArrow--sunburn svg * {
  fill: #ff5326;
}
.SearchForm__switchIcon {
  transition: transform 0.15s ease 0s;
  height: 14px;
  width: 14px;
  z-index: 99999;
}
.SearchForm__switchIcon path {
  fill: #00005e;
}
.SearchForm__switchIcon--rotated {
  transform: rotateZ(180deg);
}
.SearchForm__switch {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 31;
  transform: rotateZ(90deg);
}
.SearchForm__destinationIcon {
  left: 20px;
}
.SearchForm__hotelCheckboxContainer {
  margin: 15px 0;
}
.SearchForm__hotelCheckboxContainer--isEmpty {
  height: 22px;
}
.SearchForm__error {
  position: relative;
  bottom: -7px;
  color: #ff5326;
  animation-name: fadeIn;
  animation-duration: 800ms;
}
.SearchForm__loadingBar {
  display: none;
}
@media only screen and (min-width: 768px) {
  .SearchForm {
    padding: 0 20px;
  }
  .SearchForm--onResultsPage {
    margin-top: 20px;
  }
  .SearchForm #flightform {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .SearchForm__subForm {
    margin: 6px;
  }
  .SearchForm__origin,
  .SearchForm__passengers {
    margin-left: 0;
    width: calc(50% - 6px);
  }
  .SearchForm__destination,
  .SearchForm__dates,
  .SearchForm__submit {
    margin-right: 0;
    width: calc(50% - 6px);
  }
  .SearchForm__submit {
    margin-left: auto;
    margin-right: 0;
  }
  .SearchForm__hotelCheckboxContainer {
    margin: 10px 0;
    width: 50%;
  }
  .SearchForm__switch {
    padding: 0;
    display: flex;
    top: 10px;
    right: -22px;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border: solid 1px #7f7fae;
    border-radius: 50%;
    transition: background-color 0.15s ease 0s;
    transform: rotateZ(0deg);
  }
  .SearchForm__switch:hover {
    background-color: #ff5326;
    border-color: #ff5326;
  }
  .SearchForm__switch:hover .SearchForm__switchIcon path {
    fill: #fff;
  }
  .SearchForm--sticky .SearchForm__switch {
    top: 5px;
  }
  .SearchForm__loadingBar {
    display: block;
    position: absolute;
    bottom: -20px;
  }
}
@media only screen and (min-width: 960px) {
  .SearchForm {
    margin: 0;
    padding: 0;
  }
  .SearchForm--onResultsPage {
    padding: 5px 30px 20px;
  }
  .SearchForm--sticky {
    position: fixed;
    padding: 10px 30px;
    width: auto;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
    background-color: #00005e;
  }
  .SearchForm__input--sticky {
    height: 43px;
  }
  .SearchForm__submit--sticky {
    height: 43px;
    line-height: 43px;
  }
  .SearchForm__subForm {
    margin: 2px 3px;
    width: calc(22% - 6px);
  }
  .SearchForm__subForm--sticky {
    height: 43px;
  }
  .SearchForm__origin {
    margin-left: 0;
    width: calc(22% - 3px);
  }
  .SearchForm__passengers {
    width: calc(18% - 6px);
  }
  .SearchForm__submit {
    margin-right: 0;
    width: calc(16% - 3px);
  }
  .SearchForm__input--dest {
    padding-left: 20px;
  }
  .SearchForm__hotelCheckboxContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .SearchForm__checkbox {
    margin-left: auto;
    font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
    font-size: 14px;
  }
  .SearchForm__switch {
    right: -19px;
  }
  .SearchForm__loadingBar {
    bottom: 0;
  }
}
@media only screen and (min-width: 1100px) {
  .SearchForm__input--dest {
    padding-left: 40px;
  }
  .SearchForm__inputIcon--dest {
    left: 20px;
  }
  .SearchForm__subForm {
    margin: 2px 3px;
    width: calc(23% - 6px);
  }
  .SearchForm__dates {
    width: calc(24% - 6px);
  }
  .SearchForm__passengers {
    width: calc(17% - 6px);
  }
  .SearchForm__submit {
    margin-right: 0;
    width: calc(13% - 3px);
  }
}
@media only screen and (min-width: 1420px) {
  .SearchForm {
    margin: 0 auto;
    padding: 22px calc((100% - 1360px)/2);
  }
  .SearchForm--sticky {
    padding: 10px calc((100% - 1360px)/2) 15px;
  }
}
