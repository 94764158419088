/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.Footer {
  clear: both;
  padding: 60px 15px;
  overflow: auto;
  color: #828282;
  text-align: center;
}
.Footer--withMobileTabs {
  padding: 32px 15px;
}
.Footer__group {
  padding-bottom: 10px;
}
.Footer__desktop {
  margin: auto;
}
.Footer__mobileLinks {
  display: inline-block;
  padding: 0 10px;
}
.Footer__lists {
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: 24px;
}
.Footer__lists--hidden {
  display: none;
}
.Footer__header {
  line-height: 32px;
  color: #999;
  margin: 0;
}
.Footer__link {
  color: #00005e;
  text-decoration: none;
  cursor: pointer;
}
.Footer__link:hover {
  border-bottom: 1px solid #00005e;
}
.Footer__citydropdown {
  padding-left: 3px;
}
.Footer__cities {
  list-style: none;
}
.Footer__link__cities {
  color: #ff5326;
}
.Footer__link__cities:hover {
  border-bottom: 1px solid #ff5326;
}
.Footer__socialText {
  margin-left: 5px;
}
.Footer__social {
  display: block;
  padding: 3px 0;
  text-decoration: none;
  color: #666;
}
.Footer__social:hover .Footer__socialText {
  color: #313131;
  border-bottom: 1px solid #313131;
}
.Footer__socialLogo {
  display: inline-block;
  height: 30px;
  width: 30px;
  padding-left: 5px;
}
.Footer__img {
  width: 120px;
}
@media only screen and (min-width: 480px) {
  .Footer__mobile {
    display: none;
  }
  .Footer__desktop {
    display: block;
  }
  .Footer__group {
    float: left;
    width: 50%;
    box-sizing: border-box;
    text-align: center;
    min-height: 190px;
  }
  .Footer__copyright {
    clear: both;
    text-align: center;
    padding: 0 20px;
    line-height: 24px;
    color: #828282;
  }
  .Footer__group--social {
    width: 100%;
    margin-bottom: 30px;
  }
  .Footer__socialIcon {
    margin: auto;
    padding-bottom: 5px;
  }
  .Footer__socialIcon path {
    fill: #00005e;
  }
}
@media only screen and (min-width: 768px) {
  .Footer {
    padding: 40px 0;
  }
  .Footer__group {
    width: 25%;
    height: auto;
    padding-bottom: 30px;
  }
  .Footer__socialIcon {
    display: block;
  }
}
