/* Input styling */
/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.ItineraryRoute {
  position: relative;
}
.ItineraryRoute__separator {
  height: 1px;
  width: calc(100% - 30px);
  margin-left: 15px;
  border-top: 1px solid #dcdcdc;
}
.ItineraryRoute--outbound {
  margin-bottom: 5px;
}
.ItineraryRoute__main {
  display: flex;
  align-items: center;
  width: 100%;
}
.ItineraryRoute__airlines {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100% - 100px);
  min-width: 70px;
  padding: 10px 15px 15px;
}
.ItineraryRoute__airline {
  display: flex;
  align-items: center;
  margin-right: 15px;
  color: #828282;
}
.ItineraryRoute__airlineLogo {
  background: none no-repeat center center;
  width: 20px;
  height: 20px;
  background-size: contain;
  margin-right: 5px;
}
.ItineraryRoute__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 15px;
}
.ItineraryRoute__information {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  width: 100%;
  margin: 0 auto;
  max-width: 425px;
  margin-bottom: 6px;
}
.ItineraryRoute__timeAndAirport {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 52px;
}
.ItineraryRoute__time {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  font-family: SpartanMB, 'Arial', 'sans-serif';
}
.ItineraryRoute__daysApart {
  display: none;
  position: absolute;
  top: 0;
  right: -15px;
  font-size: 10px;
  color: #ff5326;
}
.ItineraryRoute__airport {
  color: #3b3b3b;
  text-align: center;
  word-spacing: 100vw;
}
.ItineraryRoute__usefulInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  font-size: 12px;
}
.ItinearyRoute__duration {
  text-align: center;
}
.ItineraryRoute__avoidBreak {
  display: inline-block;
}
.ItineraryRoute__numStops {
  margin-left: 4px;
}
.ItineraryRoute__directMessage {
  margin-top: -20px;
}
.ItineraryRoute__airplaneIcon {
  display: none;
  background-color: #fff;
  z-index: 1;
  width: 18px;
  height: 10px;
}
.ItineraryRoute__airplaneIcon path {
  fill: #00005e;
}
.ItineraryRoute__airplaneIcon--departing {
  position: absolute;
  left: 0;
}
.ItineraryRoute__airplaneIcon--arriving {
  position: absolute;
  right: 0;
}
.ItineraryRoute__journeyLine {
  display: flex;
  align-items: center;
  position: relative;
  margin: 5px 0 25px;
  width: 100%;
  height: 1px;
  background-color: #dcdcdc;
}
.ItineraryRoute__stop {
  position: absolute;
  left: calc(50% - 3px);
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 9.5px 0 0;
  z-index: 1;
  text-align: center;
  width: 24px;
}
.ItineraryRoute__bullet {
  background-color: #ff5326;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  color: #ff5326;
}
.ItineraryRoute__stopOverAirport {
  padding-top: 4px;
}
@media only screen and (min-width: 375px) {
  .ItineraryRoute__airplaneIcon--departing {
    display: block;
    left: -26px;
  }
  .ItineraryRoute__airplaneIcon--arriving {
    display: block;
    right: -26px;
  }
}
@media only screen and (min-width: 480px) {
  .ItineraryRoute__usefulInfo {
    min-width: 185px;
  }
  .ItineraryRoute__usefulInfo--alwaysMobile {
    min-width: 140px;
  }
}
@media only screen and (min-width: 768px) {
  .ItineraryRoute {
    margin: 0;
    padding: 0 15px;
  }
  .ItineraryRoute--alwaysMobile {
    padding: 0;
  }
  .ItineraryRoute--outbound {
    border-bottom: none;
    margin-bottom: 0;
  }
  .ItineraryRoute--homebound {
    margin-top: 18px;
  }
  .ItineraryRoute__airlines {
    top: -4px;
    padding: 0;
  }
  .ItineraryRoute__airlines--alwaysMobile {
    top: 0;
    padding: 10px 15px 15px;
  }
  .ItineraryRoute__airlineLogo {
    width: 30px;
    height: 30px;
    padding: 5px 0;
  }
  .ItineraryRoute__airlineLogo--alwaysMobile {
    width: 20px;
    height: 20px;
    padding: 0;
  }
  .ItineraryRoute__airport {
    word-spacing: normal;
  }
  .ItineraryRoute__newStop {
    margin: 18px 16px 0;
  }
  .ItineraryRoute__daysApart {
    display: block;
  }
}
