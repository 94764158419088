/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.DatePickerCalendar__content {
  color: #00005e;
  position: relative;
  padding: 15px 12px 12px;
  background: white;
  font-family: SpartanMB, 'Arial', 'sans-serif';
}
.DatePickerCalendar__monthContainer {
  padding-bottom: 10px;
}
.DatePickerCalendar__footer {
  clear: both;
}
.DatePickerCalendar__listItems {
  clear: left;
  line-height: 40px;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.DatePickerCalendar__item {
  color: #b0b0b0;
  margin-right: 15px;
  padding: 0 0 0 20px;
  float: left;
  position: relative;
}
.DatePickerCalendar__item:last-child {
  margin-right: 0;
}
.DatePickerCalendar__info {
  position: absolute;
  box-sizing: border-box;
  top: 12px;
  left: 0;
  width: 15px;
  height: 15px;
}
.DatePickerCalendar__info--direct {
  background: #00005e;
}
.DatePickerCalendar__info--indirect {
  background: #00d2ff;
}
.DatePickerCalendar__info--unavail {
  background: #efefef;
}
.DatePickerCalendar__navigator {
  position: absolute;
  top: 15px;
  width: 44px;
  height: 44px;
  cursor: pointer;
}
.DatePickerCalendar__navigator svg {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  margin-top: -6px;
  left: 50%;
  margin-left: -6px;
  fill: #00005e;
}
.DatePickerCalendar__navigator--disabled svg {
  fill: #828282;
}
.DatePickerCalendar__navigator--prev {
  left: 0;
  transform: rotate(180deg);
}
.DatePickerCalendar__navigator--next {
  right: 0;
}
.DatePickerCalendar__navigator--flexible {
  top: 57px;
}
.DatePickerCalendar__flexible__header {
  height: 42px;
}
.DatePickerCalendar__type {
  float: left;
  line-height: 42px;
  height: 42px;
  box-sizing: border-box;
  padding-left: 25px;
  background: url('/content/icons/compass.svg') no-repeat left center;
  background-size: 15px 16px;
  position: relative;
  cursor: pointer;
}
.DatePickerCalendar__type--specific {
  background-image: url('/content/icons/calendar.svg');
}
.DatePickerCalendar__type--active {
  border-bottom: 2px solid #ff5326;
}
.DatePickerCalendar__button {
  display: block;
  cursor: pointer;
  float: right;
  border: 1px solid #00005e;
  border-radius: 2px;
  line-height: 38px;
  padding: 0 12px;
}
.DatePickerCalendar__type--flexible {
  margin-left: 25px;
}
.DatePickerCalendar__type__button {
  margin-left: 20px;
}
.DatePickerCalendar__flexible__content {
  display: flex;
  flex-flow: column;
  padding: 15px 0;
}
.DatePickerCalendar__tabs {
  border: 1px solid #00005e;
  height: 40px;
  text-align: center;
  margin-bottom: 15px;
}
.DatePickerCalendar__tab {
  height: 40px;
  width: 50%;
  float: left;
  box-sizing: border-box;
  cursor: pointer;
}
.DatePickerCalendar__tab__text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.DatePickerCalendar__tab--active {
  background-color: #00005e;
  color: #fff;
}
.DatePickerCalendar__season__image {
  width: 64px;
  height: 64px;
  margin: 20px auto 26px;
}
.DatePickerCalendar__close {
  top: 17px;
  right: 3px;
}
.DatePickerCalendar__close--noText {
  display: none;
  top: 0;
  right: 0;
  z-index: 1;
}
@media only screen and (min-width: 768px) {
  .DatePickerCalendar {
    position: absolute;
    top: 53px;
    left: 50%;
    width: 0;
    bottom: 0;
    z-index: 10;
  }
  .DatePickerCalendar__content {
    position: absolute;
    top: 10px;
    left: -310px;
    width: 580px;
    box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
  }
  .DatePickerCalendar__content--sticky {
    top: 0;
  }
  .DatePickerCalendar__content--flexible {
    width: 590px;
  }
  .DatePickerCalendar__flexible__content {
    flex-flow: row;
  }
  .DatePickerCalendar__monthContainer {
    float: left;
  }
  .DatePickerCalendar__monthContainer + .DatePickerCalendar__monthContainer {
    padding-left: 20px;
  }
  .DatePickerCalendar__oneWayOrRoundTrip {
    position: absolute;
    right: 10px;
    bottom: 15px;
  }
  .DatePickerCalendar__oneWayOrRoundTrip--noList {
    position: static;
    float: right;
  }
  .DatePickerCalendar__close--noText {
    display: block;
  }
}
