/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.GoImageLayer__image {
  background: none no-repeat center center;
  background-size: cover;
  transition: transform 0.5s ease;
  height: 100%;
  width: 100%;
}
.GoImageLayer {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.GoImageLayer:hover .GoImageLayer__image {
  transform: scale(1.05);
}
.GoImageLayer__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(rgba(0, 0, 94, 0.35) 0%, transparent 40%);
}
.GoImagelayer__info {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 15px;
  left: 0;
  right: 15px;
  bottom: 15px;
  font-size: 24px;
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
  color: #fff;
}
.GoImageLayer__titleWrapper,
.GoImageLayer__priceWrapper {
  position: relative;
  flex-basis: 0;
  padding: 0 15px;
  display: inline-block;
}
.GoImageLayer__title,
.GoImageLayer__price {
  position: relative;
  flex-basis: 0;
}
.GoImageLayer__highlighter {
  width: 0;
  background-color: #50d9af;
  transition: width 0.4s ease;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 0 3px 3px 0;
}
.GoImageLayer__highlighter--price {
  border-radius: 0 0 3px 0;
}
.GoImageLayer__highlighter--visible {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .GoImageLayer {
    border-radius: 3px;
  }
  .GoImageLayer__imageContainer {
    border-radius: 3px;
  }
  .GoImagelayer__info {
    top: 20px;
    right: 20px;
    bottom: 20px;
  }
  .GoImageLayer__titleWrapper,
  .GoImageLayer__priceWrapper {
    padding: 0 20px;
  }
}
