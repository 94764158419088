/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.MobileTabs {
  display: flex;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
  font-size: 12px;
}
.MobileTabs__tab {
  text-decoration: none;
  color: #00005e;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  align-items: center;
  box-sizing: border-box;
  width: calc((100% / 3));
  text-align: center;
  color: #9b9b9b;
  border-top: 2px solid #dcdcdc;
  padding: 20px 0;
  background: #fff;
}
.MobileTabs__tab:first-child {
  border-left-width: 0;
}
.MobileTabs__tab:last-child {
  border-right-width: 0;
}
.MobileTabs__icon {
  height: 18px;
  width: 22px;
  margin: auto;
}
.MobileTabs__icon * {
  fill: #9b9b9b;
}
.MobileTabs__tab--active {
  color: #ff5326;
  border-color: #dcdcdc;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-top: 2px solid #ff5326;
}
.MobileTabs__tab--active svg * {
  fill: #ff5326;
}
.MobileTabs__text {
  display: none;
}
@media only screen and (min-width: 375px) {
  .MobileTabs__tab {
    padding: 12px 0;
  }
  .MobileTabs__text {
    display: block;
  }
}
