/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.ShareItinerary {
  display: none;
  font-size: 14px;
}
.ShareItinerary a {
  text-decoration: none;
  color: #828282;
  fill: #828282;
  cursor: pointer;
}
.ShareItinerary__toggleContainer {
  display: flex;
}
.ShareItinerary__icon {
  position: relative;
  top: 7px;
  padding-left: 5px;
  width: 20px;
}
.ShareItinerary__copyMessage {
  display: none;
  position: absolute;
  color: #fff;
  background: #00005e;
  border-radius: 2px;
  top: -20px;
  left: -5px;
  padding: 4px 8px;
  transition: opacity 1s ease-in-out;
}
.ShareItinerary__copyMessage::after {
  display: none;
  position: absolute;
  content: '';
  bottom: -6px;
  border-style: solid;
  border-width: 6px 6px 0;
  border-color: #00005e transparent;
  left: 25px;
}
.ShareItinerary__copyMessage--enabled {
  display: inline-block;
}
.ShareItinerary__copyMessage--enabled::after {
  display: block;
}
@media only screen and (min-width: 768px) {
  .ShareItinerary {
    display: block;
    left: auto;
    right: 25px;
    bottom: 15px;
  }
  .ShareItinerary a {
    color: #00005e;
  }
}
