/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
@keyframes spin-clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin-counter-clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeDown {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
@keyframes fadeUp {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}
@keyframes fadeInHalf {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}
@keyframes scaleIn {
  from {
    transform: scale(0.7);
  }
  to {
    transform: scale(1);
  }
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
@keyframes forwardBackInfinite {
  0% {
    transform: translate3d(0, 0, 0);
  }
  60% {
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}
@keyframes shadowPulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(253, 187, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(253, 187, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(253, 187, 0, 0);
  }
}
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
@keyframes treeBounce {
  0% {
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4% {
    transform: matrix3d(0.432, 0, 0, 0, 0, 0.432, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  7.91% {
    transform: matrix3d(0.889, 0, 0, 0, 0, 0.889, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  11.91% {
    transform: matrix3d(1.189, 0, 0, 0, 0, 1.189, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  15.82% {
    transform: matrix3d(1.276, 0, 0, 0, 0, 1.276, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  20.42% {
    transform: matrix3d(1.196, 0, 0, 0, 0, 1.196, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.92% {
    transform: matrix3d(1.059, 0, 0, 0, 0, 1.059, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.53% {
    transform: matrix3d(0.96, 0, 0, 0, 0, 0.96, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  34.03% {
    transform: matrix3d(0.931, 0, 0, 0, 0, 0.931, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  43.14% {
    transform: matrix3d(0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    transform: matrix3d(1.017, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  70.37% {
    transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  88.59% {
    transform: matrix3d(1.001, 0, 0, 0, 0, 1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
.VendorTile {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
  margin: 4px 0;
  animation: fadeInRight 0.25s ease-in;
  font-size: 16px;
  background-color: #fff;
  border-radius: 3px;
}
.VendorTile__content {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  width: 100%;
}
.VendorTile__content--extraPaddingTop {
  padding-top: 25px;
}
.VendorTile__vendor {
  flex-grow: 1;
  padding-left: 15px;
  font-weight: 400;
  width: 100%;
}
.VendorTile__extraVendorInfo {
  display: flex;
  flex-flow: column;
}
.VendorTile__bookWithFlag {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  padding: 3px 8px;
  background-color: #fdbb00;
  font-size: 12px;
  border-bottom-right-radius: 5px;
}
.VendorTile__price {
  margin: 0 15px;
  font-size: 1.3em;
  font-weight: bold;
  white-space: nowrap;
}
.VendorTile__multiPaxPrice {
  font-size: 14px;
  font-weight: normal;
  margin-top: 2px;
}
.VendorTile__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  margin-left: auto;
  padding: 0 7px;
  min-width: 106px;
  height: auto;
  background-color: #50d9af;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.VendorTile__btn:hover {
  opacity: 0.9;
  background-color: #50d9af;
  cursor: pointer;
}
.VendorTile__btn:active {
  opacity: 0.9;
  background-color: #50d9af;
}
.VendorTile--fadeIn {
  animation: fadeIn 0.2s ease-in;
}
.VendorTile--dohop {
  background-color: #eeeef4;
}
@media only screen and (min-width: 480px) {
  .VendorTile__content {
    flex-direction: row;
    align-items: center;
    padding: 23px 0;
  }
  .VendorTile__btn {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 768px) {
  .VendorTile__content {
    padding: 29px 0;
  }
  .VendorTile__vendor {
    padding-left: 40px;
    margin-bottom: 5px;
  }
  .VendorTile__btn {
    align-self: center;
    height: 50px;
    margin: 0 15px;
    min-width: 135px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}
