/* Input styling */
/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.FilterAirportList {
  line-height: 30px;
}
.FilterAirportList__group {
  padding-top: 20px;
}
.FilterAirportList__group:first-child {
  padding-top: 0;
}
.FilterAirportList__heading {
  margin-bottom: 5px;
  line-height: 20px;
  font-size: 14px;
  color: #828282;
}
.FilterAirportList__rows {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.FilterAirportList__row {
  padding-bottom: 5px;
}
@media only screen and (min-width: 768px) {
  .FilterAirportList__row {
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 960px) {
  .FilterAirportList__row .Checkbox__content {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 150px;
    font-size: 14px;
  }
}
