/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.Settings {
  position: relative;
  margin-right: 0;
  font-size: 16px;
  color: #00005e;
  z-index: 44;
}
.Settings__overlay {
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.Settings__bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 0;
  width: 0;
}
.Settings__dropdown {
  margin-bottom: 10px;
  text-align: left;
}
.Settings__language {
  padding-right: 5px;
}
.Settings__downArrow {
  display: inline;
  margin-left: 5px;
  position: relative;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  fill: #00005e;
  transition: all 0.15s ease;
}
.Settings__downArrow--open {
  transform: rotate(180deg);
}
.Settings__globeIcon {
  width: 25px;
  height: 35px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.Settings__globeIcon path {
  fill: #00005e;
}
.Settings__menu {
  cursor: pointer;
  text-decoration: none;
  color: #00005e;
  display: block;
  position: relative;
  padding: 10px 0;
}
.Settings__menu--dark {
  color: #fff;
}
.Settings__menu--dark .Settings__downArrow {
  fill: #fff;
}
.Settings__menu--dark .Settings__globeIcon path {
  fill: #fff;
}
.Settings__window {
  position: absolute;
  z-index: 200;
  left: 15px;
  right: 15px;
  top: 55px;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.5);
  background: #fff;
}
.Settings__window-enter {
  opacity: 0;
  transform: scale(0.9);
}
.Settings__window-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms, transform 200ms;
}
.Settings__window-exit {
  opacity: 1;
}
.Settings__window-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 200ms, transform 200ms;
}
.Settings__select {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 46px;
  -webkit-appearance: none;
}
.Settings__activate,
.Settings__cancel {
  margin-top: 10px;
  line-height: 46px;
  height: 46px;
}
.Settings__cancel {
  color: #00005e;
  background-color: transparent;
  cursor: default;
  border: 1px solid #00005e;
}
.Settings__cancel:hover {
  background-color: #efefef;
}
.Settings__arrow::after {
  content: '';
  position: absolute;
  top: 14px;
  right: 12px;
  border: 5px solid transparent;
  border-top: 7px solid #00005e;
}
.Settings__label {
  text-align: left;
  font-size: 14px;
  margin-bottom: 3px;
  margin-top: 5px;
  color: #000;
}
.Settings__closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 22px;
}
.Settings__closeIcon::before,
.Settings__closeIcon::after {
  content: '';
  position: absolute;
  top: 15px;
  right: 15px;
  margin-top: 8px;
  width: 16px;
  border-bottom: 1px solid #979797;
  transform: rotate(45deg);
}
.Settings__closeIcon::after {
  transform: rotate(-45deg);
}
@media only screen and (min-width: 375px) {
  .Settings__activate,
  .Settings__cancel {
    width: calc(50% - 10px);
    display: inline-block;
  }
  .Settings__activate {
    float: right;
  }
}
@media only screen and (min-width: 768px) {
  .Settings__menu {
    text-indent: 0;
    width: auto;
    height: auto;
    background: none;
  }
  .Settings__menu::after {
    content: '';
    display: block;
    margin: auto;
    height: 2px;
    width: 0;
    position: relative;
    top: 9px;
    background: transparent;
    transition: width 0.2s ease, background-color 0.2s ease;
  }
  .Settings__menu:hover::after {
    width: 100%;
    background: #ff5326;
  }
  .Settings__activate {
    width: 100%;
  }
  .Settings__window {
    width: 235px;
    left: auto;
    top: 8px;
    right: 0;
    transition: all 0.2s;
    transform-origin: right top;
  }
  .Settings__window::after {
    content: '';
    position: absolute;
    top: -14px;
    right: 50px;
    border: 7px solid transparent;
    border-bottom-color: #fff;
  }
  .Settings__cancel,
  .Settings__closeIcon {
    display: none;
  }
  .Settings__label {
    margin-top: 0;
  }
}
@media only screen and (min-width: 960px) {
  .Settings {
    position: relative;
  }
}
