/* Input styling */
/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.SingleItinerary {
  float: left;
  width: 100%;
}
.SingleItinerary__showOther {
  text-align: center;
  padding: 15px 0;
}
.SingleItinerary__showOtherLink {
  text-decoration: none;
  color: #00005e;
  cursor: pointer;
  font-size: 15px;
}
.SingleItinerary__loading {
  text-align: center;
  font-size: 16px;
  color: #8f8f8f;
}
.SingleItinerary__spinner {
  width: 30px;
  height: 30px;
  margin: 0 auto 10px;
  background: url(/content/img/ajax-loader-smaller.gif) no-repeat center center;
}
.SingleItinerary__notFound {
  text-align: center;
  font-size: 16px;
  color: #8f8f8f;
}
.SingleItinerary__title {
  color: #5e5e5e;
  padding-bottom: 15px;
}
