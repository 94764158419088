/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.DatePickerDay {
  text-align: center;
  line-height: 38px;
  width: 38px;
  border: 1px solid #efefef;
  cursor: pointer;
}
.DatePickerDay--hidden {
  visibility: hidden;
  border: none;
}
.DatePickerDay--indirect {
  color: #00d2ff;
}
.DatePickerDay--isBetween {
  background: #ccf6ff;
}
.DatePickerDay--selected {
  background-color: #00005e;
  color: #fff;
}
.DatePickerDay--selected.DatePickerDay--indirect {
  color: #fff;
  background-color: #00d2ff;
}
.DatePickerDay--disabled {
  background: #efefef;
  color: #b8b8b8;
  cursor: not-allowed;
}
