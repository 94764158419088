/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.NewResultsPopup {
  position: fixed;
  bottom: 15px;
  left: 5px;
  width: 330px;
  z-index: 1301;
  display: flex;
  flex-flow: column;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  box-shadow: 1px 2px 1px #d2d2d2;
}
.NewResultsPopup__content {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 18px 24px;
  height: 76%;
  background-color: #ff9d64;
}
.NewResultsPopup__contentIcon {
  height: 35px;
  width: 40px;
  margin: 10px 0 20px;
  fill: #00005e;
}
.NewResultsPopup__content__title {
  color: white;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 18px;
}
.NewResultsPopup__content__txt {
  color: #00005e;
  text-align: center;
  font-family: Dohop-Text, 'Arial', 'sans-serif';
  font-size: 16px;
  margin-top: 18px;
  margin-bottom: 10px;
}
.NewResultsPopup__btn {
  background-color: white;
  color: #ff5326;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 18px;
  height: 58px;
  outline: none;
  border: none;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}
.NewResultsPopup__btn:hover {
  background-color: #fcfcfc;
}
@media only screen and (max-width: 480px) {
  .NewResultsPopup {
    width: 95%;
    max-width: 330px;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 74px;
    z-index: z-index-over-chat-widget;
  }
}
@media only screen and (max-width: 960px) {
  .NewResultsPopup {
    bottom: 74px;
  }
}
