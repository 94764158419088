/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.ResultsHeader {
  position: relative;
}
.ResultsHeader__title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
  font-size: 16px;
  text-align: center;
}
.ResultsHeader__title > span:nth-child(2) {
  width: 10%;
}
.ResultsHeader__title > span:first-child {
  padding-right: 15px;
}
.ResultsHeader__title > span:last-child {
  padding-left: 15px;
}
.ResultsHeader__airportCode {
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
  font-size: 14px;
}
.ResultsHeader__passengers {
  margin-top: 12px;
}
.ResultsHeader__airplane {
  position: relative;
  top: 4px;
  fill: #fff;
  width: 30px;
  height: 25px;
}
.ResultsHeader__pen {
  position: absolute;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  width: 30px;
  height: 30px;
}
.ResultsHeader__pen:hover path {
  fill: #fff000;
}
@media only screen and (min-width: 375px) {
  .ResultsHeader__title {
    font-size: 18px;
    overflow: hidden;
  }
  .ResultsHeader__title > span:first-child,
  .ResultsHeader__title > span:last-child {
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 480px) {
  .ResultsHeader__title {
    font-size: 18px;
  }
  .ResultsHeader__title > span:first-child,
  .ResultsHeader__title > span:last-child {
    width: 45%;
  }
}
