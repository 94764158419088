/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.CheaperPriceIndicator {
  position: fixed;
  bottom: 12px;
  left: 12px;
  width: 340px;
  max-width: 80%;
  min-height: 50px;
  padding: 20px;
  background: #ffea34;
  font-size: 16px;
  color: #00005e;
  z-index: 2;
  border-radius: 3px;
  box-sizing: border-box;
}
.CheaperPriceIndicator__price {
  font-family: Dohop-Mono, 'Arial', 'sans-serif';
  line-height: 18px;
  margin-bottom: 3px;
}
.CheaperPriceIndicator__icon {
  width: 40px;
  height: 60px;
  float: left;
  background: url('/content/img/how-much.png') no-repeat;
  background-size: contain;
}
.CheaperPriceIndicator__rightContainer {
  float: left;
  width: calc(100% - 40px);
  padding: 0 20px;
  box-sizing: border-box;
}
.CheaperPriceIndicator__close {
  display: block;
  position: absolute;
  top: 20px;
  right: 15px;
  width: 15px;
  cursor: pointer;
  height: 15px;
}
.CheaperPriceIndicator__close::after,
.CheaperPriceIndicator__close::before {
  position: absolute;
  display: block;
  content: '';
  height: 1px;
  background-color: #00005e;
  width: 15px;
}
.CheaperPriceIndicator__close::after {
  transform: rotate(-45deg);
}
.CheaperPriceIndicator__close::before {
  transform: rotate(45deg);
}
.CheaperPriceIndicator__link {
  border-bottom: 1px solid #00005e;
  font-size: 14px;
  text-decoration: none;
  color: #00005e;
  cursor: pointer;
}
