/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.MobileMenu {
  display: inline-block;
}
.MobileMenu__overlay {
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.MobileMenu__hamburgerRight {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
}
.MobileMenu__menu {
  position: fixed;
  z-index: 200;
  top: 40px;
  left: 15px;
  right: 15px;
  border: 1px solid #979797;
  background-color: #fff;
  padding: 6px 0;
}
.MobileMenu__item {
  height: 45px;
  line-height: 45px;
}
.MobileMenu__link {
  text-decoration: none;
  color: #00005e;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
  min-width: 50%;
}
.MobileMenu__icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  fill: #00005e;
}
.MobileMenu__treeIcon path {
  stroke: #00005e;
}
.MobileMenu__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.MobileMenu__x::before,
.MobileMenu__x::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -8px;
  width: 16px;
  border-bottom: 1px solid #979797;
  transform: rotate(45deg);
}
.MobileMenu__x::after {
  transform: rotate(-45deg);
}
@media only screen and (min-width: 375px) {
  .MobileMenu__menu {
    top: 55px;
  }
}
