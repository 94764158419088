/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.BackButton {
  top: 90px;
  left: 0;
  position: absolute;
  padding: 15px;
  cursor: pointer;
  z-index: 1;
}
.BackButton::after {
  content: '';
  display: block;
  margin: auto;
  height: 2px;
  width: 0;
  position: relative;
  top: 9px;
  background: transparent;
  transition: width 0.2s ease, background-color 0.2s ease;
}
.BackButton:hover::after {
  width: 100%;
  background: #ff5326;
}
.BackButton__content {
  display: flex;
  align-items: center;
}
.BackButton__arrow {
  width: 15px;
  height: 13px;
  display: block;
  background-image: url('/content/icons/back-arrow.svg');
  background-size: contain;
  background-repeat: no-repeat;
}
.BackButton__txt {
  font-size: 14px;
}
@media only screen and (min-width: 320px) {
  .BackButton__txt {
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) {
  .BackButton {
    top: 120px;
    left: 18px;
  }
}
