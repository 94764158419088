/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.SearchError {
  border-radius: 3px;
  background-color: #fff;
  padding: 75px 20px;
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
}
.SearchError__header {
  font-size: 30px;
  margin-bottom: 5px;
}
.SearchError__image {
  margin: 60px 0;
  height: 120px;
  background: #fff no-repeat center;
  background-size: contain;
}
.SearchError__text {
  max-width: 330px;
  display: inline-block;
}
.SearchError__reset {
  color: #ff5326;
  cursor: pointer;
}
.SearchError__link {
  text-decoration: none;
  color: #00005e;
  cursor: pointer;
  color: #ff5326;
}
@media only screen and (min-width: 768px) {
  .SearchError {
    margin-top: 0;
  }
}
