/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.Inspired__header {
  box-sizing: border-box;
  padding: 24px;
  text-align: center;
}
.Inspired__title {
  font: normal 18px Dohop-Text, 'Arial', 'sans-serif';
  margin: 0 0 5px;
  text-align: center;
}
.Inspired__subtitle {
  color: #999;
}
.Inspired__cities::after {
  content: '';
  visibility: hidden;
  height: 0;
  display: block;
  clear: both;
}
.Inspired__anywhere {
  text-decoration: none;
  color: #00005e;
  cursor: pointer;
  display: block;
  font-size: 16px;
  text-align: center;
  color: #ff5326;
  float: right;
  padding-right: 15px;
}
.Inspired__container {
  display: block;
  text-decoration: none;
  height: 215px;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.Inspired--oddSize .Inspired__container:last-child {
  display: none;
}
@media only screen and (min-width: 768px) {
  .Inspired {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
  }
  .Inspired__header {
    padding: 60px 24px 24px;
  }
  .Inspired__title {
    font-size: 24px;
    line-height: 26px;
  }
  .Inspired__subtitle {
    display: block;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
  }
  .Inspired__anywhere {
    text-align: right;
    position: relative;
    bottom: 5px;
    margin-top: 5px;
    padding-right: 0;
  }
  .Inspired__container {
    float: left;
    width: 50%;
    box-sizing: border-box;
    height: 280px;
    box-shadow: none;
  }
  .Inspired__container:nth-child(odd) {
    padding-right: 10px;
  }
  .Inspired__container:nth-child(even) {
    padding-left: 10px;
  }
}
@media only screen and (min-width: 960px) {
  .Inspired__container {
    width: 25%;
    height: 140px;
  }
  .Inspired__container:nth-child(odd) {
    padding-left: 15px;
    padding-right: 0;
  }
  .Inspired__container:nth-child(even) {
    padding-right: 5px;
    padding-left: 10px;
  }
  .Inspired__container:first-of-type {
    width: 50%;
    height: 300px;
    padding-right: 10px;
    padding-left: 0;
  }
  .Inspired--oddSize .Inspired__container:last-child {
    display: block;
  }
  .Inspired--big .Inspired__container:last-of-type {
    width: 50%;
    height: 300px;
    padding-left: 10px;
    padding-right: 0;
  }
}
