/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.TicketFlight {
  position: relative;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  color: #00005e;
}
.TicketFlight__place {
  float: left;
  width: 33%;
  max-width: 150px;
}
.TicketFlight__codeshare {
  font-weight: bold;
}
.TicketFlight__time {
  float: left;
  font-weight: bold;
  width: 55%;
  max-width: 100px;
}
.TicketFlight__airport {
  float: left;
}
.TicketFlight__duration {
  color: #828282;
  float: right;
}
.TicketFlight__arrow {
  float: left;
  height: 20px;
  width: 5%;
  max-width: 40px;
  background: url('/content/svg/arrow-right-gray.svg') no-repeat left center;
  background-size: 15px 13px;
  margin-right: 20px;
}
.TicketFlight__nextDay {
  background-color: #fff000;
}
@media only screen and (min-width: 768px) {
  .TicketFlight {
    margin: auto;
    font-size: 14px;
    color: #00005e;
    padding-left: 30px;
    width: 60%;
    float: left;
  }
  .TicketFlight__place {
    width: 110px;
    min-width: auto;
  }
  .TicketFlight__date {
    float: left;
    text-align: right;
    width: 130px;
  }
  .TicketFlight__arrow {
    width: 20px;
    margin: 0;
  }
  .TicketFlight__airport {
    color: #00005e;
    margin: 0;
  }
  .TicketFlight__time {
    width: 50px;
    font-weight: bold;
    font-size: 16px;
    padding: 0 10px;
  }
  .TicketFlight__duration {
    display: block;
    color: #9b9b9b;
    margin: auto;
  }
}
