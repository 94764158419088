/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.PageInfoBanner__container {
  background-color: #f3f3f3;
}
.PageInfoBanner {
  padding: 40px 0;
}
.PageInfoBanner::after {
  content: '';
  visibility: hidden;
  height: 0;
  display: block;
  clear: both;
}
.PageInfoBanner__iconObject {
  padding: 0 40px;
}
.PageInfoBanner__iconObject__container {
  width: 100%;
  float: left;
  text-align: center;
  position: relative;
  padding-bottom: 30px;
  min-height: 125px;
}
.PageInfoBanner__text__description {
  margin-bottom: 0;
}
.PageInfoBanner__link__container {
  width: 100%;
  position: absolute;
  bottom: 20px;
  left: 0;
}
.PageInfoBanner__link {
  color: #ff5326;
  text-decoration: none;
}
.PageInfoBanner__text__title {
  font-size: 16px;
  font-family: Dohop-Text, 'Arial', 'sans-serif';
  margin-bottom: 20px;
}
.PageInfoBanner__text__title--bottom {
  margin-bottom: 25px;
}
.PageInfoBanner--bottom {
  padding-top: 40px;
}
.PageInfoBanner__image__container {
  height: 70px;
  position: relative;
}
.PageInfoBanner__image {
  max-width: 55px;
  max-height: 50px;
  bottom: 0;
  padding-bottom: 15px;
}
.PageInfoBanner__text__description a {
  color: #ff5326;
  text-decoration: none;
}
@media only screen and (min-width: 480px) {
  .PageInfoBanner__iconObject {
    padding: 0 10px;
  }
  .PageInfoBanner__iconObject__container {
    width: 33.3%;
    min-height: 135px;
  }
  .PageInfoBanner__link__container {
    bottom: 0;
  }
}
@media only screen and (min-width: 768px) {
  .PageInfoBanner--bottom {
    padding-top: 50px;
  }
  .PageInfoBanner__iconObject {
    padding: 0 35px;
  }
  .PageInfoBanner__text__title--bottom {
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 960px) {
  .PageInfoBanner__iconObject__container {
    min-height: 125px;
  }
}
