/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.DatePickerMonth {
  width: 280px;
  margin: auto;
}
.DatePickerMonth__title {
  line-height: 44px;
  border-bottom: 1px solid #00005e;
}
.DatePickerMonth__weekday {
  box-sizing: border-box;
  line-height: 24px;
  font-size: 14px;
  padding: 7px 0;
  font-weight: normal;
  text-align: center;
}
