/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.Hamburger {
  position: relative;
  width: 22px;
  height: 15px;
  outline: none;
  border: none;
  cursor: pointer;
}
.Hamburger__line,
.Hamburger__line::after,
.Hamburger__line::before {
  width: 22px;
  height: 2px;
  border-radius: 1px;
  content: '';
  background-color: #00005e;
  display: block;
  position: absolute;
  transition: all 500ms ease-in-out;
}
.Hamburger__line::after {
  bottom: -12px;
  top: auto;
  left: 0;
}
.Hamburger__line::before {
  top: 6px;
  left: 0;
}
.Hamburger--white .Hamburger__line,
.Hamburger--white .Hamburger__line::after,
.Hamburger--white .Hamburger__line::before {
  background-color: #fff;
}
