/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.FilterSlider {
  padding-top: 25px;
}
.FilterSlider:first-child {
  padding-top: 0;
}
.FilterSlider__wrapper {
  position: relative;
}
.FilterSlider__title {
  margin-bottom: 5px;
  line-height: 20px;
  color: #828282;
  font-size: 14px;
  font-family: Dohop-Text, 'Arial', 'sans-serif';
}
.FilterSlider__sliderWrapper {
  position: relative;
}
.FilterSlider__slider {
  height: 30px;
  touch-action: none;
}
.FilterSlider__bar {
  box-sizing: border-box;
  position: absolute;
  top: 12px;
  left: 0;
  height: 2px;
  background: #ff5326;
  border-radius: 5px;
}
.FilterSlider--disabled .FilterSlider__bar {
  background-color: #828282;
}
.FilterSlider__handle {
  position: relative;
  background-color: #ff5326;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  top: 3px;
  touch-action: none;
  cursor: pointer;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.FilterSlider__handle:hover {
  box-shadow: 0 0 0 7px rgba(255, 83, 38, 0.16);
}
.FilterSlider__handle:focus,
.FilterSlider__handle:active {
  background-color: #ff5326;
  border-color: #ff5326;
  box-shadow: 0 0 0 13px rgba(255, 83, 38, 0.16);
  outline: none;
}
.FilterSlider--disabled .FilterSlider__handle {
  background-color: #9b9b9b;
  box-shadow: none;
  cursor: not-allowed;
}
.handle {
  outline: none;
  z-index: 1 !important;
}
@media only screen and (min-width: 960px) {
  .FilterSlider__bar {
    height: 2px;
  }
  .FilterSlider__handle {
    height: 12px;
    width: 12px;
    top: 7px;
  }
}
.FilterSlider__hint {
  display: inline-block;
  padding-bottom: 15px;
  font-family: Dohop-Text, 'Arial', 'sans-serif';
}
.FilterSlider--disabled .FilterSlider__hint {
  color: #828282;
}
.FilterSlider__bar-0,
.FilterSlider__bar-2 {
  background: #ddd !important;
}
