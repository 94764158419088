/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.Checkbox {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  font-family: Dohop-Text, 'Arial', 'sans-serif';
  font-size: 16px;
}
.Checkbox__input {
  position: absolute;
  visibility: hidden;
}
.Checkbox__check {
  position: relative;
  display: inline-flex;
  align-items: center;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #ff5326;
  border-radius: 2px;
  box-sizing: border-box;
  transition: background-color 0.1s ease-in;
}
.Checkbox__input:checked + .Checkbox__check {
  background-color: #ff5326;
}
.Checkbox__input:checked + .Checkbox__check::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -3px;
  width: 4px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.Checkbox__check--white {
  border: 2px solid #fff;
}
.Checkbox__input:checked + .Checkbox__check--white {
  background-color: #fff;
}
.Checkbox__input:checked + .Checkbox__check--white::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -3px;
  width: 4px;
  height: 9px;
  border: solid #00005e;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.Checkbox--disabled {
  color: #828282;
}
.Checkbox--disabled .Checkbox__check {
  border-color: #828282;
}
.Checkbox__content {
  margin-left: 10px;
}
