/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.ControlRow {
  display: flex;
  align-items: center;
  padding: 10px;
}
.ControlRow:not(:last-child) {
  border-bottom: 2px solid #efefef;
}
.ControlRow--noAge {
  padding: 15px 10px;
}
.ControlRow__age {
  font-size: 14px;
  color: #828282;
}
.ControlRow__pax {
  display: flex;
  align-items: center;
  margin-left: auto;
}
