/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.City .SearchForm {
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
}
@media only screen and (min-width: 768px) {
  .City .SearchForm {
    padding-left: 0;
    padding-right: 0;
  }
}
.City .GoContent__content .RecommendedHotels__hotel {
  width: 50%;
}
.City__flexible {
  text-align: center;
  padding: 20px 0;
  color: #00005e;
}
.City__flexibleHeader {
  color: #00005e;
  font-size: 24px;
}
.City__flightHotelInfo {
  font-size: 26px;
  text-align: center;
}
.City__information {
  padding: 40px 15px 20px;
}
.City__title {
  font-size: 20px;
  text-align: center;
  padding: 10px 0 20px;
  position: relative;
}
.City__titleText {
  background-color: #f3f3f3;
  padding: 0 5px;
}
.City__subtitle {
  font-weight: normal;
  margin: 0;
}
.City__header {
  padding: 10px 0;
  color: #00005e;
  text-align: center;
}
.City__headerCity {
  font-size: 28px;
  font-weight: normal;
  margin: 0;
}
.City__logo--container {
  padding: 0 0 20px;
}
.City__logo {
  background: url('/content/img/Dohop-Simpler-icons.svg');
  height: 50px;
  width: 190px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto 5px;
  position: relative;
  top: 10px;
  display: block;
}
.City__airlineList {
  padding: 20px 0;
}
.City__airportList {
  padding-bottom: 20px;
}
.City__box {
  height: 100px;
  text-align: center;
  position: relative;
  display: block;
}
.City__box--left {
  background-color: #fff000;
  width: 100%;
}
.City__box--right {
  background-color: #00d2ff;
  width: 100%;
}
.City__boxContent {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.City__boxIcon {
  height: 50px;
  width: 70px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 10px auto 0;
}
.City__boxIcon--hotel {
  background-image: url('/content/img/icons/Hotels.png');
  height: 50px;
  width: 40px;
}
.City__boxIcon--car {
  background-image: url('/content/img/icons/Car.png');
}
.City__destinationSeperator {
  font-size: 16px;
}
.City__icon {
  background: url('/content/img/icons/flights.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 50px;
  width: 50px;
  margin: 0 auto;
}
.City__promoBanner {
  color: #999;
  text-align: center;
  padding: 20px 0;
}
@media only screen and (min-width: 768px) {
  .City__logo--container {
    padding: 45px 0;
  }
  .City__header {
    padding: 35px 0;
  }
  .City__logo {
    height: 90px;
    width: 350px;
    background-image: url('/content/img/Dohop-Simple.png');
  }
}
@media only screen and (min-width: 960px) {
  .City__outerContainer {
    position: relative;
    min-height: 500px;
  }
  .City__innerContainer {
    position: relative;
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }
  .City__background {
    height: 500px;
    position: absolute;
    display: block;
  }
  .City__title::before {
    border-top: 2px solid #dcdcdc;
    content: '';
    margin: 0 auto;
    position: absolute;
    top: 21px;
    left: 0;
    width: 100%;
    z-index: -1;
  }
  .City__travelList {
    text-align: center;
  }
  .City__box {
    background-clip: content-box;
    float: left;
    width: 50%;
    box-sizing: border-box;
    height: 175px;
  }
  .City__box--left {
    padding-right: 10px;
  }
  .City__box--right {
    padding-left: 10px;
  }
  .City__tabs {
    margin-bottom: 40px;
  }
  .City__tabs::after {
    content: '';
    visibility: hidden;
    height: 0;
    display: block;
    clear: both;
  }
  .City__flexible {
    color: #fff;
  }
  .City__go {
    min-height: 420px;
  }
}
