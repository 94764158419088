/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.CounterButton-module__counterButton___1S0n3 {
  display: flex;
}
.CounterButton-module__input___xTigR {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  border: 0;
  border-top: 2px solid #dcdcdc;
  border-bottom: 2px solid #dcdcdc;
  text-align: center;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
}
.CounterButton-module__readOnlyInput___Tun7a {
  outline: none;
}
.CounterButton-module__largeInput___WJa6P {
  width: 40px;
  max-width: 40px;
}
.CounterButton-module__addButton___M3agX {
  border-radius: 0 3px 3px 0;
}
.CounterButton-module__subButton___fEZae {
  border-radius: 3px 0 0 3px;
}
.CounterButton-module__subButton___fEZae,
.CounterButton-module__addButton___M3agX {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
  font-size: 24px;
  max-width: 30px;
  min-height: 34px;
  height: 100%;
  -webkit-user-select: none;
          user-select: none;
}
.CounterButton-module__subButton___fEZae:focus,
.CounterButton-module__addButton___M3agX:focus {
  outline: 5px auto #ff5326;
}
.CounterButton-module__minus___VBMDi {
  position: relative;
  top: -3px;
  line-height: 0;
}
.CounterButton-module__largeButton___9-Gta {
  width: 40px;
  max-width: 40px;
  min-height: 40px;
}
.CounterButton-module__buttonDisabled___GwMzV {
  background-color: #dcdcdc;
  cursor: not-allowed;
}
.CounterButton-module__buttonDisabled___GwMzV:hover {
  background-color: #dcdcdc;
  outline: 0;
}
@media only screen and (min-width: 768px) {
  .CounterButton-module__subButton___fEZae:not(.CounterButton-module__largeButton___9-Gta),
  .CounterButton-module__addButton___M3agX:not(.CounterButton-module__largeButton___9-Gta) {
    min-height: 30px;
  }
}
