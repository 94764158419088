/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.RecommendedHotels__heading {
  font-size: 16px;
  padding: 25px 0 19px;
  color: #5e5e5e;
}
.RecommendedHotels__city {
  color: #313131;
}
.RecommendedHotels__promo {
  font-size: 16px;
  color: #828282;
  display: block;
}
.RecommendedHotels__hotels {
  margin: -7px -7px 0;
}
.RecommendedHotels__hotels::after {
  content: '';
  visibility: hidden;
  height: 0;
  display: block;
  clear: both;
}
.RecommendedHotels__hotel {
  float: left;
  box-sizing: border-box;
  width: 100%;
  padding: 7px 7px 25px;
}
.RecommendedHotels__link {
  text-decoration: none;
  color: #00005e;
  cursor: pointer;
  display: block;
  border-bottom: 1px solid #cdcdcd;
}
.RecommendedHotels__link::after {
  content: '';
  visibility: hidden;
  height: 0;
  display: block;
  clear: both;
}
.RecommendedHotels__image {
  overflow: hidden;
}
.RecommendedHotels__background {
  height: 140px;
  background: none no-repeat center center;
  background-size: cover;
  transition: transform 0.5s ease;
}
.RecommendedHotels__link:hover .RecommendedHotels__background {
  transform: scale(1.05);
}
.RecommendedHotels__name {
  padding-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.RecommendedHotels__stars {
  float: right;
  padding-top: 8px;
}
.RecommendedHotels__star {
  padding-left: 6px;
  display: block;
  float: right;
  width: 17px;
  height: 17px;
}
.RecommendedHotels__star path {
  fill: #cdcdcd;
}
.RecommendedHotels__price {
  font-size: 30px;
  line-height: 40px;
  white-space: nowrap;
}
@media only screen and (min-width: 480px) {
  .RecommendedHotels__hotel--horizontal {
    width: 33.33333333%;
  }
}
