/* Input styling */
/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.ExpiredPopup__content {
  box-sizing: border-box;
  padding: 20px;
  width: 75%;
  max-width: 500px;
  font-size: 16px;
  text-align: center;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
.ExpiredPopup__clock {
  width: 46px;
  padding-bottom: 20px;
}
.ExpiredPopup__button {
  height: 50px;
  line-height: 50px;
  width: 150px;
  font-size: 16px;
  margin-top: 20px;
}
@media only screen and (min-width: 768px) {
  .ExpiredPopup__content {
    padding: 25px;
    font-size: 18px;
  }
}
