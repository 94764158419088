/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.ExploreBoxes__box {
  font-size: 25px;
  line-height: 35px;
  background-color: #fff;
  padding: 25px;
  cursor: pointer;
  margin-bottom: 18px;
  color: #00005e;
  text-decoration: none;
  display: block;
  border-radius: 3px;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
}
.ExploreBoxes__icon {
  position: relative;
  top: 10px;
  display: inline-block;
  height: 35px;
  width: 50px;
  margin: 0 10px;
  background: url('/content/img/car.svg') no-repeat center center;
}
.ExploreBoxes__icon--mountain {
  background-image: url('/content/img/Mountains.svg');
}
.ExploreBoxes__link {
  font-size: 16px;
  color: #ff5326;
}
