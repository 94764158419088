/* Input styling */
/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.Itinerary,
.Itinerary--alwaysMobile {
  position: relative;
  background: #fff;
  border-radius: 3px;
  margin: 0 10px 15px 10px;
  font-family: Dohop-Text, 'Arial', 'sans-serif';
}
.Itinerary--noPrice,
.Itinerary--noPrice--alwaysMobile {
  margin: 0;
}
.Itinerary__clickable,
.Itinerary__clickable--alwaysMobile {
  position: relative;
  cursor: pointer;
  border-radius: 3px;
}
.Itinerary__clickable::after,
.Itinerary__clickable--alwaysMobile::after {
  content: '';
  visibility: hidden;
  height: 0;
  display: block;
  clear: both;
}
.Itinerary__collapsed,
.Itinerary__collapsed--alwaysMobile {
  position: relative;
  display: flex;
  flex-direction: column;
}
.Itinerary__main,
.Itinerary__main--alwaysMobile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
}
.Itinerary__main--insideTile,
.Itinerary__main--insideTile--alwaysMobile {
  box-shadow: none;
}
.Itinerary__main--noPrice,
.Itinerary__main--withPrice--alwaysMobile {
  border-radius: 3px;
}
.Itinerary__header,
.Itinerary__header--alwaysMobile {
  height: 25px;
  margin-bottom: 15px;
}
.Itinerary__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  transition: all 0.3s ease;
  width: 12px;
  height: 12px;
}
.Itinerary__arrow path {
  fill: #ff5326;
}
.Itinerary__arrow--open {
  transform: rotate(180deg);
}
@media only screen and (min-width: 768px) {
  .Itinerary {
    border-radius: 3px;
    box-shadow: none;
    margin: 0 0 15px 0;
  }
  .Itinerary--insideTile {
    margin: 0;
  }
  .Itinerary__clickable {
    border-radius: 3px;
  }
  .Itinerary__clickable--withDetails {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
  }
  .Itinerary__collapsed {
    flex-direction: row;
  }
  .Itinerary__main--withPrice {
    border-radius: 3px 0 0 3px;
  }
  .Itinerary__main--withPrice::after {
    content: '';
    position: absolute;
    background: #fff;
    font-size: 0;
    width: 4px;
    height: 100%;
    right: -4px;
    top: 0;
  }
  .Itinerary--noShadow {
    box-shadow: none;
  }
  .Itinerary__footer {
    display: flex;
    position: relative;
    padding: 5px 15px 10px;
  }
  .Itinerary__details {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .Itinerary__airlines {
    position: relative;
    display: flex;
    max-width: calc(100% - 100px);
    padding: 10px 15px 0;
  }
  .Itinerary__airline {
    display: flex;
    align-items: center;
    margin-right: 15px;
    color: #9b9b9b;
  }
  .Itinerary__airlineLogo {
    background: none no-repeat center center;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    background-size: contain;
  }
}
