/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.AutocompleteResult {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid #efefef;
  color: #505050;
  padding: 10px 15px;
  font-family: SpartanMB, 'Arial', 'sans-serif';
  font-size: 16px;
}
.AutocompleteResult:first-child {
  border-top: none;
}
.AutocompleteResult--nested {
  padding-left: 30px;
  font-size: 12px;
}
.AutocompleteResult__title {
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
  font-size: 14px;
}
.AutocompleteResult__subTitle {
  color: #828282;
  font-size: 12px;
}
.AutocompleteResult__subTitle--active {
  color: #fff;
}
.AutocompleteResult--active.AutocompleteResult--nested::before,
.AutocompleteResult--active.AutocompleteResult--nested::after {
  border-color: #fff;
}
.AutocompleteResult--active {
  background-color: #ff5326;
  border-color: #ff5326;
  color: #fff;
}
.AutocompleteResult__main--oneLine {
  padding: 8px 0;
}
.AutocompleteResult__arrowIcon {
  margin: 0 5px;
  height: 8px;
  width: 8px;
}
.AutocompleteResult__arrowIcon path {
  fill: #505050;
}
.AutocompleteResult__icon {
  margin-bottom: -3px;
  margin-right: 10px;
  height: 18px;
  width: 18px;
}
.AutocompleteResult__icon--small {
  height: 14px;
  width: 14px;
}
.AutocompleteResult__icon--gray path {
  fill: #505050;
}
.AutocompleteResult__icon--white path {
  fill: #fff;
}
@media only screen and (min-width: 768px) {
  .AutocompleteResult--active {
    cursor: pointer;
    background-color: #ff5326;
    color: #fff;
  }
  .AutocompleteResult--active:first-child {
    border-radius: 3px 3px 0 0;
  }
  .AutocompleteResult--active:last-child {
    border-radius: 0 0 3px 3px;
  }
  .AutocompleteResult--active:only-child {
    border-radius: 3px;
  }
}
@media only screen and (min-width: 960px) {
  .AutocompleteResult {
    min-width: 300px;
  }
}
