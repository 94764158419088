/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.RecaptchaLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 64px;
}
.RecaptchaLinks--searchPage {
  background-color: #efefef;
  margin-bottom: 0;
}
.RecaptchaLinks a {
  color: #ff5326;
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .RecaptchaLinks {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 950px) {
  .RecaptchaLinks {
    max-width: 360px;
    margin: 0 auto;
    text-align: center;
  }
}
