/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.MatchMedia {
  display: none;
}
.MatchMedia--minSize_zero {
  display: block;
}
@media only screen and (min-width: 480px) {
  .MatchMedia--minSize_mobile {
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .MatchMedia--minSize_ipad {
    display: block;
  }
}
@media only screen and (min-width: 960px) {
  .MatchMedia--minSize_desktop {
    display: block;
  }
}
@media only screen and (min-width: 1024px) {
  .MatchMedia--minSize_ipad-landscape {
    display: block;
  }
}
@media only screen and (min-width: 1280px) {
  .MatchMedia--minSize_laptop {
    display: block;
  }
}
@media only screen and (min-width: 1420px) {
  .MatchMedia--minSize_max {
    display: block;
  }
}
@media only screen and (min-width: 1420px) {
  .MatchMedia--maxSize_max {
    display: none;
  }
}
@media only screen and (min-width: 1280px) {
  .MatchMedia--maxSize_laptop {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .MatchMedia--maxSize_ipad-landscape {
    display: none;
  }
}
@media only screen and (min-width: 960px) {
  .MatchMedia--maxSize_desktop {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .MatchMedia--maxSize_ipad {
    display: none;
  }
}
@media only screen and (min-width: 480px) {
  .MatchMedia--maxSize_mobile {
    display: none;
  }
}
