/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.RadioButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.RadioButton__input {
  position: absolute;
  visibility: hidden;
}
.RadioButton__radio {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
  width: 20px;
  /* Because of flexbox */
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ff5326;
}
input[type='radio']:checked + .RadioButton__radio {
  background-color: #ff5326;
}
input[type='radio']:checked + .RadioButton__radio::before {
  content: '';
  position: absolute;
  border-radius: 50%;
  border: 2px solid #efefef;
  width: 12px;
  /* Because of flexbox */
  min-width: 12px;
  height: 12px;
}
.RadioButton--disabled input[type='radio']:checked + .RadioButton__radio {
  background-color: #828282;
}
.RadioButton__content {
  padding-left: 10px;
  font-family: Dohop-Text, 'Arial', 'sans-serif';
  font-size: 16px;
}
.RadioButton--disabled .RadioButton__content {
  color: #828282;
}
