/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.ButtonRounded {
  min-height: 40px;
  padding: 8px 10px;
  background-color: transparent;
  color: #3b3b3b;
  font-family: SpartanMB-Bold, 'Arial', 'sans-serif';
  font-size: 16px;
  line-height: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 20px;
  border: 2px solid #3b3b3b;
  transition: all 0.3s ease 0s;
  outline: 0;
}
.ButtonRounded:hover {
  background-color: #3b3b3b;
  color: #fff;
}
.ButtonRounded--grass {
  border: 2px solid #50d9af;
  color: #50d9af;
}
.ButtonRounded--grass svg path,
.ButtonRounded--grass svg rect {
  transition: all 0.3s ease 0s;
  fill: #50d9af;
}
.ButtonRounded--grass:hover {
  background-color: #50d9af;
}
.ButtonRounded--grass:hover svg path,
.ButtonRounded--grass:hover svg rect {
  fill: #fff;
}
.ButtonRounded--ocean {
  border: 2px solid #00005e;
  color: #00005e;
}
.ButtonRounded--ocean svg path,
.ButtonRounded--ocean svg rect {
  transition: all 0.3s ease 0s;
  fill: #00005e;
}
.ButtonRounded--ocean:hover {
  background-color: #00005e;
}
.ButtonRounded--ocean:hover svg path,
.ButtonRounded--ocean:hover svg rect {
  fill: #fff;
}
.ButtonRounded--white {
  border: 2px solid #fff;
  color: #fff;
}
.ButtonRounded--white svg path,
.ButtonRounded--white svg rect {
  transition: all 0.3s ease 0s;
  fill: #fff;
}
.ButtonRounded--white:hover {
  background-color: transparent;
}
.ButtonRounded--white:hover svg path,
.ButtonRounded--white:hover svg rect {
  fill: #fff;
}
@media screen and (min-width: 480px) {
  .ButtonRounded {
    padding: 8px 30px;
  }
}
