/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.GoContent__flights__container {
  border-radius: 3px;
}
.GoContent__flights__container,
.GoContent__hotels {
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}
.GoContent__hotels {
  margin-top: 20px;
  padding: 30px 15px;
}
.GoContent__header {
  text-align: center;
}
.GoContent__header--results {
  position: relative;
  height: 340px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.GoContent__resultsHeader__text {
  font-size: 22px;
  color: #fff;
  font-family: Dohop-Mono, 'Arial', 'sans-serif';
  margin: 0 15px;
}
.GoContent__resultsHeader__titleContainer {
  margin-top: 50px;
}
.GoContent__icon {
  background: url('/content/icons/small_airplane_white.svg') no-repeat center;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  border: 2px solid #fff;
  display: inline-block;
  margin-bottom: 10px;
}
.GoContent__header__text {
  font-size: 20px;
  font-family: Dohop-Mono, 'Arial', 'sans-serif';
  margin-bottom: 15px;
}
.GoContent__warning {
  position: absolute;
  color: #00005e;
  background: #fff000;
  width: 55%;
  padding: 10px 15px;
  bottom: 55px;
  text-align: left;
}
.GoContent__shareDestination {
  position: absolute;
  text-align: left;
  bottom: 10px;
  left: 10px;
}
.GoContent__flightDetails {
  height: 78px;
  box-sizing: border-box;
  border-bottom: 1px solid #efefef;
  display: block;
  text-decoration: none;
  color: #00005e;
  padding: 0 15px;
}
.GoContent__flightDetails:first-of-type {
  border-top: 1px solid #efefef;
}
.GoContent__flightDetails:hover {
  background: #efefef;
}
.GoContent__flightDetails__column {
  display: inline-block;
  vertical-align: top;
}
.GoContent__price {
  font-size: 18px;
  line-height: normal;
  display: block;
  padding-top: 15px;
  float: right;
}
.GoContent__airportsAndDates {
  float: left;
  padding-top: 15px;
  line-height: normal;
}
.GoContent__price__button {
  min-width: 132px;
  padding: 8px 15px;
}
.GoContent__dates {
  display: block;
  font-size: 14px;
}
.GoContent__stay {
  display: none;
}
.GoContent__airports {
  text-align: center;
  width: 85px;
  color: #9b9b9b;
  font-size: 14px;
  margin-top: 10px;
}
.GoContent__airport--from {
  float: left;
}
.GoContent__airport--to {
  float: right;
}
.GoContent__airportIcon {
  width: 15px;
  height: 15px;
  vertical-align: top;
  background: url('/content/icons/airplane-out-gray.svg') no-repeat center;
  background-size: contain;
  display: inline-block;
}
.GoContent__search {
  width: 26%;
  display: none;
  padding-top: 21px;
}
.GoContent__more {
  text-align: center;
  margin-top: 30px;
  color: #ff5326;
  cursor: pointer;
}
.GoContent__hotels .GoContent__more {
  margin-top: 5px;
  display: block;
  text-decoration: none;
}
.GoContent__more--flights {
  padding-bottom: 30px;
}
.GoContent__noResults {
  font-weight: normal;
  padding: 0 30px;
}
.GoContent__icon--hotels {
  background-image: url('/content/icons/small_hotel.svg');
  border: 2px solid #00005e;
}
.GoContent__mobileFilters {
  position: relative;
  margin: 15px;
}
.City__go .GoContent__mobileFilters {
  display: none;
}
@media only screen and (min-width: 768px) {
  .GoContent__resultsHeader__text {
    font-size: 24px;
  }
  .GoContent__resultsHeader__titleContainer {
    margin-top: 80px;
  }
  .GoContent__warning {
    width: 45%;
    bottom: 20px;
    padding: 20px;
    font-size: 16px;
  }
  .GoContent__shareDestination {
    text-align: right;
    right: 10px;
    bottom: 20px;
  }
  .GoContent__hotels {
    padding: 30px;
  }
  .GoContent__flightDetails {
    padding: 0 30px;
    line-height: 78px;
  }
  .GoContent__airportsAndDates {
    float: none;
    padding-top: 0;
    width: 67.5%;
    line-height: 78px;
  }
  .GoContent__price {
    width: 32.5%;
    line-height: 78px;
    display: inline-block;
    padding-top: 0;
    float: none;
  }
  .GoContent__dates {
    width: 50%;
    display: inline-block;
  }
  .GoContent__stay {
    display: inline-block;
    width: 26%;
  }
  .GoContent__airports {
    width: 50%;
    float: right;
    margin-top: 0;
    max-width: 100px;
    text-align: center;
    color: #00005e;
  }
  .GoContent__stay + .GoContent__airports {
    width: 24%;
  }
  .GoContent__airportIcon {
    height: 78px;
    background-image: url('/content/icons/airplane-out-ocean.svg');
  }
  .GoContent__topWrapper {
    position: relative;
    margin-bottom: 10px;
  }
  .GoContent__mobileFilters {
    display: flex;
  }
  .GoContent__header {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
}
@media only screen and (min-width: 960px) {
  .GoContent__mobileFilters {
    display: none;
  }
  .GoContent__topWrapper {
    display: none;
  }
  .GoContent__warning {
    width: 55%;
  }
}
@media only screen and (min-width: 1280px) {
  .GoFrontPage .GoContent__flights--withHotels {
    width: calc(100% - 400px);
    float: left;
    box-sizing: border-box;
  }
  .GoFrontPage .GoContent__hotels {
    box-sizing: border-box;
    width: 380px;
    float: right;
    margin-top: 0;
  }
  .GoFrontPage .GoContent__header--results {
    height: 400px;
  }
  .GoFrontPage .GoContent__resultsHeader__titleContainer {
    margin-top: 120px;
  }
}
