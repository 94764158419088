/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.HotelsExplore {
  background: #fff;
  padding: 20px 0;
  margin-bottom: 18px;
  border-radius: 3px;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
}
.HotelsExplore__image {
  width: 43px;
  height: 50px;
  background-image: url('/content/img/hotel.svg');
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
}
.HotelsExplore__header {
  font-size: 20px;
  font-family: Dohop-Mono, 'Arial', 'sans-serif';
  text-align: center;
  margin-top: 28px;
  padding: 0 20px;
}
.HotelsExplore__rows {
  margin-top: 20px;
}
.HotelsExplore__row__container {
  height: 80px;
  border-bottom: 1px solid #e1e1e1;
  padding: 20px 25px 15px;
  box-sizing: border-box;
}
.HotelsExplore__row {
  padding: 0;
  display: block;
  text-decoration: none;
  color: #00005e;
  cursor: pointer;
}
.HotelsExplore__row:first-of-type .HotelsExplore__row__container {
  border-top: 1px solid #e1e1e1;
}
.HotelsExplore__row:hover {
  background-color: #efefef;
}
.HotelsExplore__row__number {
  margin-bottom: 5px;
}
.HotelsExplore__star {
  padding-left: 6px;
  display: block;
  float: left;
  width: 20px;
  height: 20px;
}
.HotelsExplore__star:first-of-type {
  padding-left: 0;
}
.HotelsExplore__star path {
  fill: #00005e;
}
.HotelsExplore__more {
  color: #ff5326;
  display: block;
  text-decoration: none;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}
