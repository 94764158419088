/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.ItineraryStop {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #3b3b3b;
}
.ItineraryStop__duration {
  text-align: right;
  padding-right: 10px;
  margin-right: 10px;
  width: 18%;
  font-size: 14px;
  color: #ff5326;
}
.ItineraryStop__info {
  position: relative;
  padding: 50px 0 50px 20px;
}
.ItineraryStop__line {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-image: linear-gradient(to bottom, #3b3b3b 50%, transparent 50%);
  background-size: 100% 10px;
}
.ItineraryStop__item {
  font-size: 12px;
  margin: 10px 0;
}
.ItineraryStop__title {
  position: absolute;
  top: 0;
  font-family: SpartanMB, 'Arial', 'sans-serif';
  color: #ff5326;
}
.ItineraryStop__title--only {
  position: relative;
}
.ItineraryStop__itemIcon {
  margin-right: 5px;
  width: 16px;
  height: 16px;
  fill: #ff5326;
}
.ItineraryStop__groundTransitAirports {
  display: flex;
  font-size: 12px;
}
.ItineraryStop__arrow {
  margin: 0 5px;
  width: 20px;
  height: 10px;
  fill: #ff5326;
}
.ItineraryStop__button {
  margin: 5px 0;
}
