/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.PlainPage {
  min-height: 100vh;
  background: #00d2ff;
}
.PlainPage__padding {
  padding: 100px 0 10px;
}
.PlainPage__content {
  margin: 0 auto;
  padding: 30px 10px;
  max-width: 360px;
  border-radius: 5px;
}
