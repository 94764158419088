/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.HeaderTabs {
  display: inline-block;
  position: relative;
}
.HeaderTabs::after {
  content: '';
  visibility: hidden;
  height: 0;
  display: block;
  clear: both;
}
.HeaderTabs__link {
  text-decoration: none;
  color: #00005e;
  cursor: pointer;
  display: block;
  padding: 0 10px;
  position: relative;
}
.HeaderTabs--light .HeaderTabs__link {
  color: #fff;
}
.HeaderTabs__tab {
  z-index: 4;
  position: relative;
  float: left;
  outline: 0;
}
.HeaderTabs__tab:first-child .HeaderTabs__link {
  padding-left: 0;
}
.HeaderTabs__text {
  position: relative;
  display: block;
  padding: 10px 0;
  font-size: 16px;
}
.HeaderTabs__text--ellipsis {
  max-width: 135px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.HeaderTabs__text--active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #ff5326;
  transition: all 0.2s;
}
.HeaderTabs__slider {
  position: absolute;
  bottom: 0;
  height: 2px;
  background: #ff5326;
  transition: all 0.3s ease;
}
.HeaderTabs__menuPositioner {
  position: absolute;
  z-index: 1;
  top: 50px;
  left: -50%;
}
.HeaderTabs__menuContent {
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.5);
  background: #fff;
  border-radius: 3px;
  transition: all 0.2s;
  transform-origin: top;
}
.HeaderTabs__menuContent::after {
  content: '';
  position: absolute;
  top: -14px;
  left: 50%;
  margin-left: -5px;
  border: 7px solid transparent;
  border-bottom-color: #fff;
}
.HeaderTabs__menuContent-enter {
  opacity: 0;
  transform: scale(0.9);
}
.HeaderTabs__menuContent-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms, transform 200ms;
}
.HeaderTabs__menuContent-exit {
  opacity: 1;
}
.HeaderTabs__menuContent-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 200ms, transform 200ms;
}
.HeaderTabs__menuLink {
  text-decoration: none;
  color: #00005e;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 13px 25px 13px 13px;
  border-left: 2px solid transparent;
  min-width: 150px;
}
.HeaderTabs__menuLink:hover {
  border-color: #ff5326;
}
.HeaderTabs__menuIcon {
  height: 20px;
  width: 20px;
  margin-right: 15px;
}
.HeaderTabs__co2Icon g {
  stroke: #00005e;
  stroke-width: 2px;
}
@media only screen and (min-width: 960px) {
  .HeaderTabs__link {
    padding: 0 20px;
  }
  .HeaderTabs__text--ellipsis {
    max-width: 160px;
  }
}
