.CloseButton {
  cursor: pointer;
  position: absolute;
  padding: 10px;
  top: 10px;
  right: 20px;
}
.CloseButton__text {
  padding-right: 10px;
  float: left;
  line-height: 15px;
}
.CloseButton__button {
  background: url('/content/icons/close.svg') no-repeat center center;
  width: 10px;
  height: 15px;
  float: right;
}
