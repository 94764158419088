/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.TransferStepAd__image {
  padding-top: 56.25%;
  height: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.5s ease;
}
.TransferStepAd__imageOpacity {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
}
.TransferStepAd {
  position: relative;
  height: auto;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  box-sizing: border-box;
}
.TransferStepAd:hover .TransferStepAd__image {
  transform: scale(1.05);
}
.TransferStepAd:hover .TransferStepAd__highlight {
  width: 100%;
}
.TransferStepAd:hover .TransferStepAd__imageOpacity {
  background: none;
}
.TransferStepAd__imageContainer {
  position: relative;
}
.TransferStepAd__contentContainer {
  position: absolute;
  top: 20px;
  padding: 25px 0;
  width: 100%;
}
.TransferStepAd__content {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  font-family: SpartanMB, 'Arial', 'sans-serif';
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease-in;
}
.TransferStepAd__text {
  position: absolute;
  background-color: rgba(255, 83, 38, 0.9);
  padding: 10px 15px;
  border-radius: 0 3px 3px 0;
}
@media only screen and (min-width: 768px) {
  .TransferStepAd {
    width: 50%;
  }
  .TransferStepAd:first-child {
    margin-right: 15px;
  }
}
@media only screen and (min-width: 960px) {
  .TransferStepAd {
    width: 100%;
  }
  .TransferStepAd:first-child {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
