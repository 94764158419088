/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.Disclaimer {
  position: relative;
  border-radius: 4px;
  padding: 10px 0;
  background-color: white;
}
.Disclaimer__title,
.Disclaimer__subTitle,
.Disclaimer h3 {
  color: #00d2ff;
  font-weight: 600;
  font-family: SpartanMB, 'Arial', 'sans-serif';
  cursor: pointer;
}
.Disclaimer__title {
  font-size: 16px;
}
.Disclaimer__subTitle,
.Disclaimer h3 {
  font-size: 14px;
}
.Disclaimer__text--isOpen {
  max-height: 1000px;
}
.Disclaimer__arrow {
  margin-left: 8px;
  transition: all 0.3s ease;
  width: 12px;
  height: 12px;
}
.Disclaimer__arrow path {
  fill: #00d2ff;
}
.Disclaimer__arrow--open {
  transform: rotate(180deg);
}
.Disclaimer__body strong {
  font-weight: normal;
}
.Disclaimer__body li {
  padding-left: 10px;
}
.Disclaimer__body ul {
  list-style: none;
  padding-left: 15px;
  margin-bottom: 0;
}
.Disclaimer__body ul li::before {
  display: inline-block;
  content: '\2022';
  color: #00d2ff;
  font-weight: bold;
  width: 15px;
  margin-left: -15px;
}
.Disclaimer__body,
.Disclaimer p {
  font-size: 14px;
  line-height: 20px;
  color: #7b7b7b;
}
.Disclaimer__body--codeshare {
  text-align: right;
}
.Disclaimer__icon {
  margin-right: 8px;
  height: 18px;
  width: 18px;
}
.Disclaimer__icon path {
  fill: #00d2ff;
}
