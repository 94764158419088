/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.BigLogoHeader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
}
.BigLogoHeader--transparent {
  box-shadow: none;
}
.BigLogoHeader__content {
  padding: 20px 0 40px;
}
.BigLogoHeader__link {
  display: block;
  margin: 0 auto;
}
.BigLogoHeader__logo {
  height: 54px;
  width: 210px;
}
@media only screen and (min-width: 375px) {
  .BigLogoHeader__logo {
    height: 59px;
    width: 230px;
  }
}
@media only screen and (min-width: 768px) {
  .BigLogoHeader {
    position: static;
    padding: 20px 0 0;
    margin-bottom: 0;
    box-shadow: none;
  }
  .BigLogoHeader__content {
    padding: 85px 0;
  }
  .BigLogoHeader__logo {
    height: 85px;
    width: 334px;
  }
}
@media only screen and (min-width: 960px) {
  .BigLogoHeader__bar {
    padding: 0;
  }
}
