/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.SearchPage__left,
.SearchPage__right {
  display: none;
  float: left;
  width: 180px;
}
.SearchPage__right {
  width: 240px;
}
.SearchPage__results {
  float: left;
  width: 100%;
  box-sizing: border-box;
}
.SearchPage .Section--header {
  border-bottom: 1px solid #dedede;
}
@media only screen and (min-width: 768px) {
  .SearchPage .Section--content .Constraint {
    padding-top: 25px;
  }
}
@media only screen and (min-width: 960px) {
  .SearchPage__left {
    display: block;
  }
  .SearchPage__left .DoubleClick {
    /* doubleclick is 10px wider than the sidebar */
    margin-left: -5px;
  }
  .SearchPage__results {
    width: calc(100% - 180px);
    padding-left: 25px;
  }
}
@media only screen and (min-width: 1420px) {
  .SearchPage__right {
    display: block;
  }
  .SearchPage__results {
    width: calc(100% - 180px - 240px);
    padding-right: 25px;
  }
}
