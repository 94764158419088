/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.PageNotFound {
  margin-top: -1px;
  background-color: #fff;
  padding: 20px 15px 100px;
}
.PageNotFound::after {
  content: '';
  visibility: hidden;
  height: 0;
  display: block;
  clear: both;
}
.PageNotFound__explaination {
  text-align: center;
}
.PageNotFound__ghost {
  background: url('/content/img/Ghost.svg') no-repeat center;
  height: 120px;
  background-size: contain;
  margin-bottom: 35px;
}
.PageNotFound__title {
  font: 24px Dohop-Mono, 'Arial', 'sans-serif';
}
.PageNotFound__home {
  text-decoration: none;
  color: #ff5326;
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  .PageNotFound {
    padding-top: 0;
  }
  .PageNotFound__title {
    font-size: 36px;
  }
}
