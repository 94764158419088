/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.DateMatrix {
  display: none;
  position: relative;
}
.DateMatrix__text {
  position: relative;
  cursor: pointer;
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
  font-size: 14px;
  color: #50d9af;
}
.DateMatrix__text::after {
  position: absolute;
  content: '';
  display: block;
  margin: auto;
  height: 2px;
  width: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: width 0.2s ease, background-color 0.2s ease;
}
.DateMatrix__text:hover::after {
  width: 100%;
  background: #50d9af;
}
.DateMatrix__text::after {
  bottom: -2px;
}
.DateMatrix__text--active::after {
  position: absolute;
  content: '';
  display: block;
  margin: auto;
  height: 2px;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #50d9af;
}
.DateMatrix__text--active::after {
  bottom: -2px;
}
.DateMatrix__bannerAmount {
  border-bottom: 1px solid #50d9af;
}
.DateMatrix:hover ~ .DateMatrix__bannerAmount {
  border: none;
}
.DateMatrix__bannerAmount--active {
  border: none;
}
.DateMatrix__content {
  margin-top: 20px;
  padding: 20px 30px 30px 20px;
  background: white;
  position: absolute;
  z-index: 10;
  width: 700px;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.5);
}
.DateMatrix__headingReturn {
  position: absolute;
  top: 0;
  left: 0;
}
.DateMatrix__textReturn {
  padding-left: 25px;
  background: url(/content/img/flex-left.png) no-repeat left center;
  background-size: 14px 14px;
  transform: translateX(-100%) rotate(-90deg);
  transform-origin: top right;
}
.DateMatrix__headingDepart {
  padding: 0 0 20px 40px;
}
.DateMatrix__textDepart {
  display: inline-block;
  padding-right: 25px;
  background: url(/content/img/flex-right.png) no-repeat right center;
  background-size: 14px 14px;
}
.DateMatrix__faresWrapper {
  /* to position "Return" label */
  position: relative;
  padding-left: 40px;
}
.DateMatrix__fares {
  position: relative;
  border-top: 1px solid #d1d1d1;
  border-left: 1px solid #d1d1d1;
}
.DateMatrix__fares::after {
  content: '';
  visibility: hidden;
  height: 0;
  display: block;
  clear: both;
}
.DateMatrix__cell {
  position: relative;
  font-size: 14px;
  float: left;
  white-space: nowrap;
  text-align: center;
  line-height: 40px;
  height: 40px;
  width: 12.5%;
  box-sizing: border-box;
  border-bottom: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
}
.DateMatrix__cell--break0 {
  background: rgba(80, 217, 175, 0.7);
}
.DateMatrix__cell--break1 {
  background: rgba(80, 217, 175, 0.3);
}
.DateMatrix__cell--break3 {
  background: rgba(255, 83, 38, 0.3);
}
.DateMatrix__cell--break4 {
  background: rgba(255, 83, 38, 0.8);
}
.DateMatrix__cell--selected,
.DateMatrix__cell--date:hover {
  border-color: #00005e;
}
.DateMatrix__cell--selected::before,
.DateMatrix__cell--date:hover::before {
  content: '';
  position: absolute;
  left: -1px;
  top: -1px;
  bottom: -1px;
  border-right: 1px solid #00005e;
}
.DateMatrix__cell--selected::after,
.DateMatrix__cell--date:hover::after {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: -1px;
  border-bottom: 1px solid #00005e;
}
.DateMatrix__link {
  text-decoration: none;
  color: #00005e;
  cursor: pointer;
  color: inherit;
  position: relative;
  display: block;
  height: 100%;
}
.DateMatrix__searchIcon {
  display: block;
  height: 100%;
  background: url(/content/img/search-icon.svg) no-repeat center center;
  background-size: 13px 20px;
}
.DateMatrix__info {
  margin-top: 30px;
  padding-left: 40px;
  line-height: 25px;
}
.DateMatrix__info__block {
  width: 25px;
  height: 25px;
  background: #50d9af;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.DateMatrix__info__block--expensive {
  background-color: #ff5326;
  margin-left: 20px;
}
.DateMatrix__pointer {
  position: absolute;
  left: 50%;
  bottom: -20px;
  margin-left: -5px;
  border: 7px solid transparent;
  border-top: none;
  border-bottom-color: #fff;
  z-index: 11;
}
@media only screen and (min-width: 768px) {
  .DateMatrix {
    display: block;
    margin-top: 18px;
  }
}
@media only screen and (min-width: 960px) {
  .DateMatrix__content {
    width: 810px;
  }
}
