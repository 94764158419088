/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.GoFilters {
  padding: 20px;
}
.GoFilters__oneway {
  margin-top: 30px;
}
.GoFilters__nonstop {
  margin-bottom: 30px;
}
.GoFilters .FilterBox .FilterBox .FilterBox__title {
  font-size: 16px;
}
.GoFilters__row {
  margin-bottom: 12px;
}
@media only screen and (min-width: 960px) {
  .GoFilters {
    padding: 0;
  }
}
