/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.Constraint {
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
.Constraint--laptop {
  max-width: 1280px;
}
.Constraint--slim {
  max-width: 990px;
  position: relative;
}
.Constraint--front {
  max-width: 1200px;
  position: relative;
}
.Constraint--absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.Constraint--wide {
  max-width: 1420px;
}
@media only screen and (min-width: 768px) {
  .Constraint--front,
  .Constraint--slim,
  .Constraint--wide {
    padding: 0 20px;
  }
}
@media only screen and (min-width: 960px) {
  .Constraint--front,
  .Constraint--slim,
  .Constraint--wide {
    padding: 0 30px;
  }
}
