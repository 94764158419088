/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.DatePickerSeasons {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
}
.DatePickerSeasons__season {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: calc(50% - 5px);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
  margin-top: 10px;
  font-size: 16px;
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
  line-height: 14px;
  cursor: pointer;
  white-space: nowrap;
}
.DatePickerSeasons__season:nth-child(2n) {
  margin-left: 10px;
}
.DatePickerSeasons__season--winter {
  color: #00d2ff;
  border: 2px solid #00d2ff;
}
.DatePickerSeasons__season--spring {
  color: #50d9af;
  border: 2px solid #50d9af;
}
.DatePickerSeasons__season--summer {
  color: #fdbb00;
  border: 2px solid #fdbb00;
}
.DatePickerSeasons__season--fall {
  color: #ff5326;
  border: 2px solid #ff5326;
}
.DatePickerSeasons__seasonIcon {
  width: 64px;
  height: 64px;
  margin: 20px auto 26px;
}
.DatePickerSeasons__seasonIcon--winter {
  color: #00d2ff;
}
.DatePickerSeasons__seasonIcon--winter path {
  stroke: #00d2ff;
}
.DatePickerSeasons__seasonIcon--spring {
  fill: #50d9af;
}
.DatePickerSeasons__seasonIcon--summer path {
  fill: #fdbb00;
}
.DatePickerSeasons__seasonIcon--fall path {
  fill: #ff5326;
}
.DatePickerSeasons__season:hover {
  transition: background-color 0.3s;
}
.DatePickerSeasons__season--active,
.DatePickerSeasons__season:hover {
  color: #fff;
}
.DatePickerSeasons__season--active.DatePickerSeasons__season--winter,
.DatePickerSeasons__season:hover.DatePickerSeasons__season--winter {
  background-color: #00d2ff;
}
.DatePickerSeasons__season--active.DatePickerSeasons__season--spring,
.DatePickerSeasons__season:hover.DatePickerSeasons__season--spring {
  background-color: #50d9af;
}
.DatePickerSeasons__season--active.DatePickerSeasons__season--summer,
.DatePickerSeasons__season:hover.DatePickerSeasons__season--summer {
  background-color: #fdbb00;
}
.DatePickerSeasons__season--active.DatePickerSeasons__season--fall,
.DatePickerSeasons__season:hover.DatePickerSeasons__season--fall {
  background-color: #ff5326;
}
.DatePickerSeasons__season--active svg *,
.DatePickerSeasons__season:hover svg * {
  stroke: #fff;
  fill: #fff;
}
.DatePickerSeasons__season--active:hover,
.DatePickerSeasons__season:hover:hover {
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .DatePickerSeasons {
    width: calc(50% - 5px);
  }
  .DatePickerSeasons__season {
    margin-left: 0;
    width: 140px;
  }
  .DatePickerSeasons__season:nth-child(2n) {
    margin-left: 10px;
  }
}
