/* Input styling */
/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.ItineraryPrice {
  position: relative;
  display: flex;
  text-align: center;
  min-width: 180px;
  padding: 0 15px 15px;
  margin-left: auto;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
}
.ItineraryPrice__rightWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
}
.ItineraryPrice__priceContainer {
  margin-bottom: 10px;
}
.ItineraryPrice__price {
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 7px;
  white-space: nowrap;
  font-size: 27px;
  line-height: 27px;
  color: #00005e;
  font-family: SpartanMB-Bold, 'Arial', 'sans-serif';
}
.ItineraryPrice__price--pax {
  font-size: 14px;
  line-height: 14px;
  padding: 0;
}
.ItineraryPrice__button {
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
}
.ItineraryPrice__vendor {
  color: #828282;
  font-size: 14px;
}
.ItineraryPrice__protectionLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 15px;
  bottom: 0;
  background-color: #fdbb00;
  height: 24px;
  width: 24px;
  box-shadow: 1px 1px 2px rgba(20, 20, 20, 0.25);
}
.ItineraryPrice__protectionLabel__tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ItineraryPrice__protectionIcon {
  height: 16px;
  width: 16px;
}
@media only screen and (min-width: 768px) {
  .ItineraryPrice {
    flex-direction: column;
    padding: 20px 12px 20px 6px;
    width: auto;
    min-width: 200px;
    justify-content: space-between;
    border-radius: 0 3px 3px 0;
  }
  .ItineraryPrice::before {
    content: '';
    position: absolute;
    background: #fff;
    width: 4px;
    height: 100%;
    left: -4px;
    top: 0;
  }
  .ItineraryPrice__priceContainer {
    margin: 0;
    padding: 5px 0;
  }
  .ItineraryPrice__price--moreSpace {
    padding: 5px 0;
  }
  .ItineraryPrice__protectionLabel {
    right: 0;
    top: 15px;
    left: auto;
  }
  .ItineraryPrice__button {
    min-width: 140px;
    margin: 0 auto;
  }
}
