/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.TransferStepHeader {
  width: 100%;
  height: 70px;
  box-shadow: 1px 3px 5px #dcdcdc;
  position: relative;
}
.TransferStepHeader__left {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  left: 15px;
}
.TransferStepHeader__logo {
  width: 90px;
  height: 40px;
}
.TransferStepHeader__popover {
  background-color: white;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.5);
  padding: 20px;
  bottom: 0;
  margin: auto;
  z-index: 999999;
  height: 100px;
  width: 200px;
}
.TransferStepHeader__right {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: auto;
  right: 0;
}
.TransferStepHeader__right > .Settings {
  right: 15px;
}
.TransferStepHeader__divider {
  display: block;
  background-color: #00005e;
  height: 45px;
  width: 1px;
}
.TransferStepHeader__customerService {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: inherit;
  text-decoration: none;
  margin: 0 14px;
}
.TransferStepHeader__txt {
  color: #7b7b7b;
  font-weight: 300;
  margin: 2px 0;
}
.TransferStepHeader__phone {
  font-weight: 500;
  margin: 2px 0;
}
.TransferStepHeader--flex {
  display: flex;
}
@media only screen and (min-width: 768px) {
  .TransferStepHeader {
    height: 85px;
  }
  .TransferStepHeader__left {
    margin-left: 20px;
  }
  .TransferStepHeader__right > .Settings {
    right: 30px;
    position: relative;
    font-size: 16px;
  }
  .TransferStepHeader__divider {
    height: 58px;
  }
  .TransferStepHeader__customerService {
    font-size: 16px;
  }
}
