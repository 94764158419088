/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.AutocompleteResults__content {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
}
.AutocompleteResults__arrow {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px;
  margin-left: -5px;
  border: 7px solid transparent;
  border-top: none;
  border-bottom-color: #fff;
  z-index: 11;
}
.AutocompleteResults__arrow--sunburn {
  border-bottom-color: #ff5326;
}
.AutocompleteResults__header {
  padding: 10px 15px;
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
  font-size: 16px;
}
@media only screen and (min-width: 768px) {
  .AutocompleteResults {
    z-index: 300;
    /* position this at the bottom of parent element */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .AutocompleteResults__content {
    position: absolute;
    border-radius: 3px;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
    min-width: 100%;
    top: 10px;
    left: 0;
    margin-right: -400px;
    /* allow to grow naturally to the right */
  }
}
@media only screen and (min-width: 960px) {
  .AutocompleteResults__content {
    /* allow to grow naturally to the right */
    margin-right: -10000px;
  }
}
