/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.ContentHeader {
  position: relative;
}
.ContentHeader__titles {
  width: 100%;
}
.ContentHeader__titles--clickable {
  display: inline-block;
  outline: 0;
  cursor: pointer;
}
.ContentHeader__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  height: 180px;
}
.ContentHeader__banner {
  background: #00005e;
  background: url(/content/svg/world_map_blue.svg) no-repeat center center;
  background-size: 200% 200%;
  /* this is for IE11 else we would use cover */
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.ContentHeader__content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  width: 100%;
  z-index: 1;
}
.ContentHeader__title {
  font-family: Dohop-Mono, 'Arial', 'sans-serif';
  font-size: 36px;
  color: #fff;
  text-align: center;
}
.ContentHeader__subtitle {
  text-align: center;
  margin: 12px 0;
  color: #fff;
  font-family: Dohop-Text, 'Arial', 'sans-serif';
  font-size: 16px;
}
@media only screen and (min-width: 375px) {
  .ContentHeader__banner {
    background-size: 130% 200%;
    /* this is for IE11 else we would use cover */
  }
}
@media only screen and (min-width: 768px) {
  .ContentHeader {
    padding-top: 20px;
  }
  .ContentHeader__container {
    width: 100%;
    height: auto;
  }
  .ContentHeader__banner {
    display: none;
  }
  .ContentHeader .HeaderBar {
    padding-bottom: 50px;
  }
  .ContentHeader__contentLeft {
    justify-content: flex-start;
  }
  .ContentHeader__titles {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 50px 0;
  }
  .ContentHeader__title {
    padding: 0;
    text-align: left;
    font-size: 42px;
    color: #00005e;
  }
  .ContentHeader__subtitle {
    text-align: left;
    font-size: 18px;
    padding-top: 20px;
  }
  .ContentHeader__content {
    padding: 0;
  }
  .ContentHeader--results .HeaderBar {
    float: right;
    width: calc(100% - 180px);
    padding-left: 25px;
    padding-bottom: 30px;
  }
  .ContentHeader--results .ContentHeader__content {
    top: 0;
  }
}
