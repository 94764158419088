/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.SortButtonsLoading {
  display: flex;
  padding: 2px 0;
  width: 100%;
}
.SortButtonsLoading__tile--containerLeft,
.SortButtonsLoading__tile--containerRight {
  display: flex;
}
.SortButtonsLoading__tile--containerLeft {
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  min-width: 120px;
}
.SortButtonsLoading__tile--containerRight {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}
.SortButtonsLoading__tile--title {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #e6e6e6;
  background: linear-gradient(to right, #e6e6e6 8%, #d6d6d6 18%, #e6e6e6 33%);
  background-size: 800px 104px;
  border-radius: 3px;
  height: 18px;
  width: 70px;
  background-color: #e6e6e6;
}
.SortButtonsLoading__tile--price {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #e6e6e6;
  background: linear-gradient(to right, #e6e6e6 8%, #d6d6d6 18%, #e6e6e6 33%);
  background-size: 800px 104px;
  border-radius: 3px;
  height: 25px;
  width: 100px;
  background-color: #e6e6e6;
  margin-bottom: 10px;
}
.SortButtonsLoading__tile--duration {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #e6e6e6;
  background: linear-gradient(to right, #e6e6e6 8%, #d6d6d6 18%, #e6e6e6 33%);
  background-size: 800px 104px;
  border-radius: 3px;
  height: 14px;
  width: 50px;
  background-color: #e6e6e6;
}
