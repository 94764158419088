/* Input styling */
/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.ItinerarySeparator {
  position: relative;
  background-color: #fff;
  z-index: 1;
  padding-top: 4px;
  height: 24px;
}
.ItinerarySeparator__firstBite,
.ItinerarySeparator__secondBite {
  content: '';
  position: absolute;
  background-color: #efefef;
  width: 8px;
  height: 16px;
  transition: height 0.15s ease-in-out;
}
.ItinerarySeparator__firstBite {
  left: 0;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  box-shadow: inset -5px 0 5px -5px rgba(0, 0, 0, 0.4);
}
.ItinerarySeparator__secondBite {
  right: 0;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: inset 5px 0 5px -5px rgba(0, 0, 0, 0.4);
}
.ItinerarySeparator__line {
  position: absolute;
  top: 11px;
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, #dcdcdc 50%, transparent 50%);
  background-size: 10px 100%;
}
@media only screen and (min-width: 768px) {
  .ItinerarySeparator {
    height: auto;
    width: 23px;
    padding-top: 0;
  }
  .ItinerarySeparator__firstBite,
  .ItinerarySeparator__secondBite {
    height: 8px;
    width: 17px;
  }
  .ItinerarySeparator__firstBite {
    top: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 16px;
    box-shadow: inset 0 -7px 7px -7px rgba(0, 0, 0, 0.4);
  }
  .ItinerarySeparator__secondBite {
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 16px;
    box-shadow: inset 0 7px 7px -7px rgba(0, 0, 0, 0.4);
  }
  .ItinerarySeparator__firstBite--detailsOpen {
    top: 1px;
    height: 1px;
    background-color: transparent;
    box-shadow: none;
  }
  .ItinerarySeparator__secondBite--detailsOpen {
    display: none;
  }
  .ItinerarySeparator__line {
    top: 0;
    bottom: 0;
    left: 8px;
    width: 1px;
    height: 100%;
    background-image: linear-gradient(to bottom, #dcdcdc 50%, transparent 50%);
    background-size: 100% 10px;
  }
}
