/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.ItinerarySectionLoading {
  width: 100%;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.ItinerarySectionLoading__header {
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 45px;
  padding: 15px;
  background-color: #00005e;
  border-radius: 3px 3px 0 0;
}
.ItinerarySectionLoading__route {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 54px;
}
.ItinerarySectionLoading__headerTile,
.ItinerarySectionLoading__routeTile {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 22px;
  width: 150px;
}
.ItinerarySectionLoading__headerSmallTile {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 16px;
  width: 200px;
  margin-top: 10px;
}
.ItinerarySectionLoading__separator {
  height: 1px;
  background-color: #dcdcdc;
  margin: 0 15px;
}
@media only screen and (min-width: 768px) {
  .ItinerarySectionLoading__headerTile,
  .ItinerarySectionLoading__routeTile {
    width: 200px;
  }
  .ItinerarySectionLoading__headerSmallTile {
    width: 250px;
  }
}
