/* Input styling */
/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
@keyframes spin-clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin-counter-clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeDown {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
@keyframes fadeUp {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}
@keyframes fadeInHalf {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}
@keyframes scaleIn {
  from {
    transform: scale(0.7);
  }
  to {
    transform: scale(1);
  }
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
@keyframes forwardBackInfinite {
  0% {
    transform: translate3d(0, 0, 0);
  }
  60% {
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}
@keyframes shadowPulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(253, 187, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(253, 187, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(253, 187, 0, 0);
  }
}
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
@keyframes treeBounce {
  0% {
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4% {
    transform: matrix3d(0.432, 0, 0, 0, 0, 0.432, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  7.91% {
    transform: matrix3d(0.889, 0, 0, 0, 0, 0.889, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  11.91% {
    transform: matrix3d(1.189, 0, 0, 0, 0, 1.189, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  15.82% {
    transform: matrix3d(1.276, 0, 0, 0, 0, 1.276, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  20.42% {
    transform: matrix3d(1.196, 0, 0, 0, 0, 1.196, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.92% {
    transform: matrix3d(1.059, 0, 0, 0, 0, 1.059, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.53% {
    transform: matrix3d(0.96, 0, 0, 0, 0, 0.96, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  34.03% {
    transform: matrix3d(0.931, 0, 0, 0, 0, 0.931, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  43.14% {
    transform: matrix3d(0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    transform: matrix3d(1.017, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  70.37% {
    transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  88.59% {
    transform: matrix3d(1.001, 0, 0, 0, 0, 1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
.MultiTicket__content {
  position: fixed;
  margin: 80px auto;
  margin-bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-bottom: 10px;
  overflow: auto;
  background-color: #f9f9f9;
  border-radius: 2px;
  animation: fadeUp 0.2s ease-in;
  z-index: 74;
}
.MultiTicket__overlay {
  display: none;
}
.MultiTicket__title {
  color: #00005e;
  font-size: 16px;
  text-align: center;
  padding: 20px 0;
  line-height: 20px;
  background-color: white;
}
.MultiTicket__notice {
  position: relative;
  background: #fff000;
  padding: 25px 30px;
  line-height: 20px;
  font-size: 16px;
}
.MultiTicket__notice::after {
  content: '';
  visibility: hidden;
  height: 0;
  display: block;
  clear: both;
}
.MultiTicket__close {
  display: none;
}
.MultiTicket__noticeText {
  text-align: center;
  line-height: 22px;
}
.MultiTicket__header {
  position: relative;
  line-height: 35px;
  font-size: 14px;
}
.MultiTicket__header::after {
  content: '';
  visibility: hidden;
  height: 0;
  display: block;
  clear: both;
}
.MultiTicket__header p {
  margin-top: 0;
  margin-bottom: 0;
}
.MultiTicket__airlineLogo {
  margin: 8px 0 0 15px;
  height: 20px;
  width: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  float: left;
}
.MultiTicket__airline {
  float: left;
  line-height: 35px;
  font-size: 16px;
  margin-left: 10px;
  color: #828282;
}
.MultiTicket__footer__topBorder {
  background-image: linear-gradient(to right, #dcdcdc 50%, rgba(255, 255, 255, 0) 0%);
  background-size: 6px 2px;
  background-repeat: repeat-x;
  left: 0;
  height: 4px;
  width: 100%;
  position: absolute;
}
.MultiTicket__footer {
  position: relative;
  width: 100%;
  height: 60px;
}
.MultiTicket__footer::after {
  content: '';
  visibility: hidden;
  height: 0;
  display: block;
  clear: both;
}
.MultiTicket__button {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  min-width: 85px;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.MultiTicket__vendor {
  line-height: 15px;
  font-size: 14px;
  color: #828282;
  max-width: 100px;
  text-align: right;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.MultiTicket__price {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.MultiTicket__single {
  font-weight: bold;
  font-size: 16px;
}
.MultiTicket__total {
  text-transform: lowercase;
  color: #00005e;
}
.MultiTicket__sum__total {
  float: right;
  padding: 15px;
}
.MultiTicket__ticketNumber {
  float: left;
  width: 35px;
  font-size: 20px;
  text-align: center;
  box-sizing: border-box;
  line-height: 31px;
  border: 2px solid;
  border-radius: 50%;
}
.MultiTicket__transferLink {
  text-decoration: none;
  color: #00005e;
  cursor: pointer;
  color: #1695ca;
  line-height: 20px;
  font-size: 12px;
  font-weight: bold;
}
.MultiTicket__flights {
  padding: 15px 0;
}
.MultiTicket__flights .TicketFlight {
  padding-bottom: 10px;
}
.MultiTicket__rowContainer {
  background-color: white;
}
.MultiTicket__rowContainer::after {
  content: '';
  visibility: hidden;
  height: 0;
  display: block;
  clear: both;
}
.MultiTicket__row {
  padding: 20px 15px 0;
  border-bottom: 2px solid #dcdcdc;
}
.MultiTicket__border {
  display: none;
  background-image: linear-gradient(to right, #dcdcdc 50%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 6px 1px;
  background-repeat: repeat-x;
  left: 0;
  height: 1px;
  width: 100%;
  position: absolute;
}
.MultiTicket__disclaimer {
  background-color: white;
  padding: 10px;
}
.MultiTicket__mobile--hidden {
  display: none;
}
@media only screen and (min-width: 480px) {
  .MultiTicket__single {
    margin-right: 20px;
    float: left;
  }
  .MultiTicket__total {
    float: left;
  }
}
@media only screen and (min-width: 768px) {
  .MultiTicket__overlay {
    background-color: rgba(0, 0, 0, 0.3);
    display: block;
  }
  .MultiTicket__title {
    font-size: 18px;
  }
  .MultiTicket__close {
    display: block;
  }
  .MultiTicket__content {
    width: 768px;
    max-height: 80vh;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.25);
    background-color: white;
  }
  .MultiTicket__notice {
    display: block;
    margin-bottom: 0;
    background: #fff000;
  }
  .MultiTicket__airline {
    float: left;
    line-height: 35px;
    font-size: 20px;
    margin-left: 20px;
    color: #9b9b9b;
  }
  .MultiTicket__border {
    display: block;
  }
  .MultiTicket__flights {
    padding-bottom: 10px;
  }
  .MultiTicket__flights::after {
    content: '';
    visibility: hidden;
    height: 0;
    display: block;
    clear: both;
  }
  .MultiTicket__footer {
    float: right;
    text-align: right;
    width: calc(40% - 30px);
    margin-top: 15px;
  }
  .MultiTicket__vendor {
    max-width: none;
  }
  .MultiTicket__button {
    height: 44px;
    max-width: 105px;
    font-size: 16px;
    line-height: 40px;
    margin-left: 10px;
    position: static;
    transform: none;
    float: right;
  }
  .MultiTicket__price {
    width: 135px;
    position: static;
    transform: none;
    float: right;
  }
  .MultiTicket__total {
    width: 100%;
    float: right;
    margin-top: 15px;
  }
  .MultiTicket__single {
    width: 100%;
    float: right;
    margin-right: 0;
  }
  .MultiTicket__single--centered {
    margin-top: 13px;
  }
  .MultiTicket__airlineLogo {
    margin-left: 20px;
    margin-top: 5px;
    width: 30px;
    height: 30px;
  }
  .MultiTicket__row {
    border: none;
    padding: 20px 25px 0;
  }
  .MultiTicket__sum__total {
    padding-right: 25px;
  }
  .MultiTicket__mobile--hidden {
    display: block;
  }
  .MultiTicket__disclaimer {
    padding: 0 30px;
  }
  .MultiTicket__noticeText {
    text-align: center;
  }
  .MultiTicket__noticeIcon {
    display: none;
    text-align: center;
  }
}
