/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.GoFrontPage__left {
  display: none;
  float: left;
  width: 180px;
}
.GoFrontPage__results {
  float: left;
  width: 100%;
  box-sizing: border-box;
}
@media only screen and (min-width: 768px) {
  .GoFrontPage__content {
    padding-top: 20px;
  }
}
@media only screen and (min-width: 960px) {
  .GoFrontPage__left {
    display: block;
  }
  .GoFrontPage__results {
    width: calc(100% - 180px);
    padding-left: 25px;
  }
}
