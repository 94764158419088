/* Input styling */
/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.ItineraryDetailedRoute {
  position: relative;
  margin-bottom: 15px;
}
.ItineraryDetailedRoute__line {
  position: absolute;
  top: 0;
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, #dcdcdc 50%, transparent 50%);
  background-size: 10px 100%;
}
.ItineraryDetailedRoute__itinerary {
  display: flex;
  padding: 0 15px;
}
.ItineraryDetailedRoute__route {
  width: 50%;
}
.ItineraryDetailedRoute__heading {
  display: inline-block;
  margin: 20px 0;
  background-color: #fdbb00;
  padding: 5px 10px;
}
.ItineraryDetailedRoute__mobileHeading {
  display: flex;
  background-color: #fdbb00;
  padding: 5px 10px;
  margin-bottom: 15px;
}
.ItineraryDetailedRoute__headingTitle {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  font-family: SpartanMB, 'Arial', 'sans-serif';
}
.ItineraryDetailedRoute__headingSubTitle {
  font-size: 14px;
  font-family: SpartanMB, 'Arial', 'sans-serif';
}
.ItineraryDetailedRoute__mobileHeadingSubTitle {
  margin-left: auto;
}
.ItineraryDetailedRoute__headingIcon {
  display: none;
  margin-right: 15px;
  width: 30px;
  height: 15px;
  fill: #00005e;
}
.ItineraryDetailedRoute__flight {
  font-size: 16px;
}
.ItineraryDetailedRoute__timeAndAirport {
  display: flex;
  align-items: center;
}
.ItineraryDetailedRoute__timeAndAirport--withNewDay {
  margin-bottom: -8px;
}
.ItineraryDetailedRoute__date {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 16px;
  padding-right: 10px;
  width: 18%;
}
.ItineraryDetailedRoute__airplaneIcon {
  margin-right: 12px;
  width: 20px;
  height: 14px;
  fill: #3b3b3b;
}
.ItineraryDetailedRoute__airport {
  font-family: SpartanMB, 'Arial', 'sans-serif';
  font-size: 16px;
  font-weight: 600;
}
.ItineraryDetailedRoute__airTime {
  display: flex;
  align-items: center;
}
.ItineraryDetailedRoute__duration {
  text-align: right;
  padding-right: 10px;
  margin-right: 10px;
  width: 18%;
  font-size: 14px;
  color: #3b3b3b;
}
.ItineraryDetailedRoute__flightInfo {
  position: relative;
  border-left: 1px solid #3b3b3b;
  padding: 50px 0 50px 20px;
}
.ItineraryDetailedRoute__airline {
  position: absolute;
  top: 0;
  margin-top: 5px;
}
.ItineraryDetailedRoute__airlineText {
  white-space: nowrap;
}
.ItineraryDetailedRoute__airlineInfo {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.ItineraryDetailedRoute__airlineSeparator {
  padding: 0 5px;
}
.ItineraryDetailedRoute__airlineLogo {
  background: none no-repeat center center;
  width: 25px;
  height: 25px;
  margin-right: 7px;
  background-size: contain;
}
.ItineraryDetailedRoute__airlineMessage {
  font-size: 14px;
  margin-left: 32px;
  white-space: nowrap;
}
.ItineraryDetailedRoute__groundTransitAirports {
  display: flex;
}
.ItineraryDetailedRoute__groundTransitIata {
  font-weight: 600;
  color: #ff5326;
  background: #fff000;
  margin-left: 3px;
}
.ItineraryDetailedRoute__newDay {
  background-color: #fdbb00;
  padding: 2px 2px 0;
  border-radius: 2px;
}
@media only screen and (min-width: 768px) {
  .ItineraryDetailedRoute {
    border-top: none;
  }
  .ItineraryDetailedRoute__heading {
    display: block;
    background-color: transparent;
    padding: 0;
  }
  .ItineraryDetailedRoute__headingIcon {
    display: block;
  }
  .ItineraryDetailedRoute__headingSubTitle {
    color: #828282;
    margin-left: 45px;
  }
}
