/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.Button {
  background-color: #ff5326;
  box-sizing: border-box;
  text-decoration: none;
  border-radius: 3px;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  text-align: center;
  font-family: SpartanMB, 'Arial', 'sans-serif';
  color: #fff;
  display: inline-block;
  line-height: 30px;
  padding: 0 15px;
  outline: 0;
  border: 0;
  transition: all 0.15s ease-in-out;
}
.Button:hover {
  background-color: #ff633a;
}
.Button:active {
  background-color: #ff5326;
}
.Button--ocean {
  background-color: #00005e;
}
.Button--ocean:hover {
  background-color: #000072;
}
.Button--ocean:active {
  background-color: #00005e;
}
.Button--big {
  height: 45px;
  line-height: 45px;
  font-size: 16px;
}
.Button--small {
  padding: 0 5px;
  min-width: 60px;
  line-height: 24px;
  font-size: 11px;
}
.Button--select {
  line-height: 40px;
  font-size: 16px;
}
.Button--disabled {
  background-color: #dcdcdc;
  cursor: not-allowed;
}
.Button--disabled:hover {
  background-color: #dcdcdc;
}
.Button--fill {
  width: 100%;
}
.Button--sort {
  color: #5e5e5e;
  background-color: transparent;
  border: 1px solid #c5c5c5;
}
.Button--sort:hover,
.Button--sort:active {
  background-color: rgba(0, 0, 0, 0.05);
}
.Button--sort:active {
  box-shadow: inset 0 3px 0 rgba(0, 0, 0, 0.05);
}
.Button--sort.Button--active {
  color: #00005e;
  background-color: transparent;
}
.Button--sort.Button--active:hover {
  background-color: rgba(10, 10, 10, 0);
}
.Button--sort.Button--active:active {
  background-color: transparent;
}
.Button--loadMore {
  font-size: 11px;
  text-transform: uppercase;
  margin: 30px 20px;
  height: 46px;
  line-height: 46px;
  box-sizing: content-box;
  display: block;
  background-color: #d1d1d1;
  color: #828282;
  border: none;
}
.Button--loadMore:hover {
  background-color: #ebebeb;
}
.Button--loadMore:active {
  background-color: #d1d1d1;
  box-shadow: inset 0 3px 0 rgba(0, 0, 0, 0.05);
}
.Button--form {
  height: 43px;
  line-height: 43px;
  font-size: 16px;
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
}
.Button--form:focus {
  outline: 5px auto #ff5326;
}
@media only screen and (min-width: 768px) {
  .Button--loadMore {
    margin: 30px auto;
    line-height: 50px;
    height: 50px;
    font-size: 16px;
  }
  .Button--big {
    height: 55px;
    line-height: 55px;
  }
  .Button--sort {
    color: #9b9b9b;
  }
  .Button--sort:hover,
  .Button--sort:active {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .Button--sort:active {
    box-shadow: none;
  }
  .Button--select {
    line-height: 45px;
  }
  .Button--form {
    height: 53px;
    line-height: 53px;
  }
}
