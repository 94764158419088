/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.InfoFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.InfoFooter__text {
  max-width: 800px;
}
.InfoFooter__img {
  width: 120px;
}
.InfoFooter__hunang {
  display: none;
}
@media only screen and (min-width: 480px) {
  .InfoFooter {
    text-align: center;
  }
  .InfoFooter__text {
    line-height: 24px;
    padding: 0 20px;
  }
}
