/*
** Mixins
*/
/* Input styling */
/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/*
** Styles
*/
.DesktopLoading {
  display: flex;
  background: #fff;
  position: relative;
  height: 160px;
  border-radius: 3px;
  margin: auto;
  margin-bottom: 20px;
}
.DesktopLoading__price {
  position: relative;
  margin-left: auto;
  height: 100%;
  width: 200px;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
  border-radius: 0 3px 3px 0;
}
.DesktopLoading__price::before {
  content: '';
  position: absolute;
  background: #fff;
  width: 4px;
  height: 100%;
  left: -4px;
  top: 0;
}
.DesktopLoading__price__tiles {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 25px 20px;
}
.DesktopLoading__price__tiles--vendor {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  display: block;
  border-radius: 2px;
  height: 12px;
  width: 54px;
}
.DesktopLoading__price__tiles--price {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  display: block;
  border-radius: 2px;
  margin-top: 13px;
  height: 16px;
  width: 110px;
}
.DesktopLoading__price__tiles--btn {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #ffe1d9;
  background: linear-gradient(to right, #ffe1d9 8%, #f3cabf 18%, #ffe1d9 33%);
  background-size: 800px 104px;
  display: block;
  border-radius: 2px;
  margin-top: 16px;
  height: 45px;
  width: 150px;
}
.DesktopLoading__itinerary {
  position: relative;
  width: 80%;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px 0 0 3px;
}
.DesktopLoading__itinerary::after {
  position: absolute;
  background: #fff;
  content: '';
  font-size: 0;
  width: 4px;
  height: 100%;
  right: -4px;
  top: 0;
}
.DesktopLoading__itinerary__tiles {
  padding: 20px;
}
.DesktopLoading__itinerary__tiles--airline {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  display: block;
  border-radius: 2px;
  height: 14px;
  width: 110px;
}
.DesktopLoading__itinerary__tiles--outbound {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  display: block;
  border-radius: 2px;
  height: 16px;
  width: 100%;
  margin-top: 18px;
}
.DesktopLoading__itinerary__tiles--homebound {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  display: block;
  border-radius: 2px;
  height: 16px;
  width: 100%;
  margin-top: 14px;
}
.DesktopLoading__itinerary__tiles--details {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  display: block;
  border-radius: 2px;
  height: 14px;
  width: 62px;
  position: absolute;
  right: 20px;
  bottom: 16px;
}
.DesktopLoading__itinerary__tiles--share {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  display: block;
  border-radius: 2px;
  height: 14px;
  width: 44px;
  position: absolute;
  left: 20px;
  bottom: 16px;
}
@media only screen and (min-width: 768px) and (max-width: 960px) {
  .DesktopLoading {
    height: 156px;
  }
  .DesktopLoading__itinerary__tiles {
    padding: 22px 18px;
  }
}
