/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.CartrawlerWidget .WidgetTemplate {
  color: #00005e !important;
  font-family: SpartanMB, 'Arial', 'sans-serif' !important;
  border: none !important;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2) !important;
  margin-bottom: 50px !important;
}
.CartrawlerWidget .EngineDeeplink,
.CartrawlerWidget .ProductName__title-appendix,
.CartrawlerWidget .DateDisplayInline__dates {
  color: #00005e !important;
}
.CartrawlerWidget .WidgetTemplate1--return,
.CartrawlerWidget .WidgetTemplate1Return__search-summary {
  height: auto !important;
}
.CartrawlerWidget .WidgetTemplate1Return__search-summary .SearchSummary1 {
  height: auto !important;
  max-height: calc(75px - 1em) !important;
}
.CartrawlerWidget .ctc-button {
  color: #fff !important;
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif' !important;
  margin-bottom: 15px !important;
}
.CartrawlerWidget .ProductBlock__middleRow {
  padding: 0 !important;
}
.CartrawlerWidget .ProductAttributes {
  border: none !important;
}
.CartrawlerWidget .ProductAttribute__value {
  text-align: left !important;
  width: 40% !important;
}
.CartrawlerWidget .ProductBlock__image {
  border: none !important;
}
.CartrawlerWidget .ctt-truncate {
  overflow: visible !important;
  white-space: normal !important;
}
@media only screen and (min-width: 960px) {
  .CartrawlerWidget .ctc-button {
    margin-bottom: 0 !important;
  }
}
