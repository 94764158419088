/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.Bar__container {
  position: relative;
  display: none;
  align-items: center;
  padding: 5px 15px;
}
.Bar__container--mobile {
  display: flex;
}
.Bar--white {
  background-color: #fff;
}
.Bar__link {
  text-decoration: none;
  margin-right: auto;
}
.Bar__logo {
  width: 90px;
  height: 25px;
}
.Bar__right {
  margin-left: auto;
}
.Bar__hamburger {
  margin-left: 15px;
}
@media only screen and (min-width: 768px) {
  .Bar {
    max-width: 1360px;
    margin: 0 auto 25px;
    padding: 0 20px;
  }
  .Bar--noPadding {
    padding: 0;
  }
  .Bar__logo {
    margin-top: 8px;
    margin-right: 25px;
  }
  .Bar__container {
    display: flex;
    justify-content: flex-start;
    padding: 0;
  }
  .Bar__container--mobile {
    display: none;
  }
}
@media only screen and (min-width: 960px) {
  .Bar {
    padding: 0 30px;
  }
  .Bar--noPadding {
    padding: 0;
  }
  .Bar__logo {
    margin-top: 8px;
    margin-right: 60px;
  }
}
