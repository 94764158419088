/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.DatePickerMonths {
  text-align: center;
  margin-left: auto;
}
.DatePickerMonths__month {
  width: calc(50% - 5px);
  display: inline-block;
  box-sizing: border-box;
  border: 2px solid #00005e;
  border-radius: 3px;
  margin-top: 10px;
  font-size: 14px;
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
  padding: 0 10px;
  height: 42px;
  line-height: 42px;
  cursor: pointer;
  white-space: nowrap;
}
.DatePickerMonths__month:nth-child(2n) {
  margin-left: 10px;
}
.DatePickerMonths__month:hover {
  background-color: #00005e;
  color: #fff;
}
.DatePickerMonths__month--active {
  background-color: #00005e;
  color: #fff;
}
.DatePickerMonths__month--active svg * {
  stroke: #fff;
  fill: #fff;
}
.DatePickerMonths__month--active:hover {
  background-color: #00005e;
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .DatePickerMonths {
    width: 290px;
  }
  .DatePickerMonths__month {
    margin-left: 0;
    width: 140px;
  }
  .DatePickerMonths__month:nth-child(2n) {
    margin-left: 10px;
  }
}
