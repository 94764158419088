/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.GoDestinations__more {
  width: 100%;
}
.GoDestinations__inspire {
  position: relative;
  padding: 15px;
  border-radius: 3px;
}
.GoDestinations__inspireWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.GoDestinations__textWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.GoDestinations__inspire__header {
  color: #ff5326;
  font-size: 20px;
}
.GoDestinations__warning {
  font-size: 20px;
  display: none;
}
.GoDestinations__continent::after {
  content: '';
  visibility: hidden;
  height: 0;
  display: block;
  clear: both;
}
.GoDestinations__continent__header {
  font-size: 28px;
  padding-left: 20px;
  margin-bottom: 15px;
}
.GoDestinations__cities {
  display: flex;
  flex-wrap: wrap;
}
.GoDestinations__city {
  height: 220px;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  margin-bottom: 20px;
}
@media only screen and (min-width: 480px) {
  .GoDestinations__city {
    width: 50%;
    margin-bottom: 20px;
    padding-left: 10px;
  }
  .GoDestinations__city:nth-child(odd) {
    padding-left: 0;
    padding-right: 10px;
  }
  .GoDestinations__inspire__header {
    padding-right: 0;
  }
}
@media only screen and (min-width: 768px) {
  .GoDestinations__inspireWrapper {
    flex-direction: row;
  }
  .GoDestinations__textWrapper {
    margin-bottom: 0;
  }
  .GoDestinations__inspire {
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
  }
  .GoDestinations__inspire__header {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .GoDestinations__warning {
    display: block;
  }
  .GoDestinations__continent__header {
    padding-left: 0;
  }
}
@media only screen and (min-width: 960px) {
  .GoDestinations {
    padding: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .GoDestinations__city {
    width: 50%;
  }
}
@media only screen and (min-width: 1280px) {
  .GoDestinations__city {
    height: 250px;
  }
}
