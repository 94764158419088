/* Input styling */
/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.ItineraryDetails {
  position: relative;
  display: flex;
  padding: 0 30px;
  max-height: 0;
  overflow: hidden;
}
.ItineraryDetails__open {
  max-height: 1300px;
}
.ItineraryDetails__line {
  position: absolute;
  left: 15px;
  right: 15px;
  height: 1px;
  background-color: #dcdcdc;
}
.ItineraryDetails__route {
  margin: 15px 0;
  width: 50%;
}
