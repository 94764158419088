/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.Heading {
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
  font-size: 24px;
  color: #00005e;
  padding: 15px;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .Heading {
    font-size: 28px;
    margin: 0 0 25px;
    color: #ff5326;
  }
}
