/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.OneWayOrRoundTrip--tabs {
  display: flex;
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
  margin-bottom: 5px;
}
.OneWayOrRoundTrip__button {
  white-space: nowrap;
  min-width: 120px;
  font-family: SpartanMB-SemiBold, 'Arial', 'sans-serif';
  border: 2px solid #00005e;
  color: #00005e;
  background-color: #fff;
}
.OneWayOrRoundTrip__button:hover {
  color: #fff;
  background-color: #00005e;
}
.OneWayOrRoundTrip__linkButton {
  padding: 5px;
  color: #9b9b9b;
  border-radius: 3px;
  outline: 0;
  transition: color 0.15s;
  cursor: pointer;
}
.OneWayOrRoundTrip__linkButton:hover {
  color: #ff5326;
}
.OneWayOrRoundTrip__linkButton:focus {
  background-color: #dcdcdc;
}
.OneWayOrRoundTrip__linkButton--active {
  color: #ff5326;
}
.OneWayOrRoundTrip__roundTrip {
  margin-right: 10px;
}
@media only screen and (min-width: 960px) {
  .OneWayOrRoundTrip__linkButton--ocean {
    color: #7f7fae;
  }
  .OneWayOrRoundTrip__linkButton--ocean:hover {
    color: #fff;
  }
  .OneWayOrRoundTrip__linkButton--ocean.OneWayOrRoundTrip__linkButton--active {
    color: #fff;
  }
}
