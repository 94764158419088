/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.FilterMain .Checkbox__content {
  position: relative;
  max-width: 150px;
}
.FilterMain__filter {
  margin-bottom: 12px;
  font-size: 16px;
  overflow: hidden;
}
.FilterMain__filter:last-child {
  margin-bottom: 0;
}
