/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
.MobileFilters {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
}
.MobileFilters__button {
  background-color: rgba(255, 255, 255, 0.6);
}
.MobileFilters__button--sort {
  margin-right: 10px;
}
.MobileFilters__button--filter {
  margin-left: 10px;
}
.MobileFilters__buttonInnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.MobileFilters__filterIcon {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}
@media only screen and (min-width: 320px) {
  .MobileFilters__button--sort {
    margin-right: 2px;
  }
  .MobileFilters__button--filter {
    margin-left: 2px;
  }
}
@media only screen and (min-width: 480px) {
  .MobileFilters {
    justify-content: center;
  }
  .MobileFilters__button--sort {
    margin-right: 15px;
  }
  .MobileFilters__button--filter {
    margin-left: 15px;
  }
}
