/* Colors - Bedow design */
/* Colors */
/* Border colors */
/* Border */
/* Background colors */
/* Font sizes */
/* Dohop mono */
/* Box shadows */
/* Font colors */
/* sidebar width - and everything that should be aligned with the sidebar */
/* Used in Dohop GetInspired photo padding */
/* Media queries sizes  */
/* Breakpoints */
/*
 * Gutters
 */
/* This is the gutter between rows */
/*
 * Loading
 */
/* Z INDEXES */
/* Design */
/* Hover transition */
/* Fonts families */
/* Font styles */
/* Input styling */
.MobileAutocomplete {
  height: 100vh;
  background: #fff;
}
.MobileAutocomplete__input {
  width: 100%;
}
.MobileAutocomplete__topBar {
  padding: 15px 0;
  background-color: #00005e;
}
.MobileAutocomplete__row {
  display: flex;
  align-items: center;
  padding: 0 15px;
}
.MobileAutocomplete__cancelButton {
  padding-left: 12px;
  font-family: SpartanMB, 'Arial', 'sans-serif';
  font-size: 16px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}
